import useSWR from "swr";

import { ApiError, Coach } from "types/api";

export const useCoaches = () => {
  const { data, error, mutate } = useSWR<Coach[], ApiError>(`coach`);

  return {
    coaches: data,
    coachesError: error,
    isCoachesLoading: !data && !error,
    mutateCoaches: mutate,
  };
};
