import { INCIDENT_LIMIT } from "constants/pagination-limits";
import useAuth from "hooks/useAuth";
import { useSWRPaginate } from "hooks/useSWRPaginate";

import { ApiError, IncidentType } from "types/api";

export const useIncidents = (isEnterpriseView: boolean) => {
  const { organizationId } = useAuth();
  const url = organizationId
    ? `incident-management/${organizationId}/incident${
        isEnterpriseView ? "/enterprise" : ""
      }`
    : undefined;
  const { data, error, mutate } = useSWRPaginate<IncidentType, ApiError>({
    url,
    limit: INCIDENT_LIMIT,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    incidents: data ?? undefined,
    incidentsError: error,
    isIncidentsLoading: !data && !error,
    mutateIncidents: mutate,
  };
};
