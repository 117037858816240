import useAuth from "hooks/useAuth";
import { useSWRPaginate } from "hooks/useSWRPaginate";

import { ApiError, AssessmentCountOrgName } from "types/api";

export const useAssessments = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWRPaginate<
    AssessmentCountOrgName,
    ApiError
  >({
    url: organizationId
      ? `organization/${organizationId}/assessment`
      : undefined,
    limit: 200,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    assessments: data,
    assessmentsError: error,
    isAssessmentsLoading: !data && !error,
    mutateAssessments: mutate,
  };
};
