import useSWR from "swr";

import { ApiError, ConversationParticipant } from "types/api";

export const useChatParticipants = (conversationSid?: string | null) => {
  const { data, error, mutate } = useSWR<ConversationParticipant[], ApiError>(
    conversationSid ? `chat/conversation/${conversationSid}/participants` : null
  );

  return {
    participants: data,
    participantsError: error,
    isParticipantsLoading: !data && !error,
    mutateParticipants: mutate,
  };
};
