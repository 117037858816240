import { Prisma } from "@prisma/client";

export const ROLE_IDS = [
  "4eb9a965-9c68-4b45-82bd-4fb9599e5fb5",
  "a5777b73-482f-47a1-9887-a95ebf61dad4",
  "c25269d8-4b5e-4e6e-b742-ee895a61c50a",
  "db0a4d6f-1c60-4e43-86c3-ea59c8a94e0b",
  "387a7efa-d062-4d67-b568-a5f2f29b3702",
  "e393ad1a-4aa0-443a-a354-50f2aeb86915",
];

export const ROLES: Prisma.RoleCreateInput[] = [
  {
    role_name: "PHI Admin",
    role_description: "Level 1 Description",
    role_id: ROLE_IDS[0],
    actions: {
      connect: [
        { action_id: "ManageAllClients" },
        { action_id: "ManageEnterpriseClients" },
        { action_id: "OrganizationRead" },
        { action_id: "OrganizationCreate" },
        { action_id: "OrganizationUpdate" },
        { action_id: "OrganizationDelete" },
        { action_id: "RiskRegistryRead" },
        { action_id: "AssessmentRead" },
        { action_id: "TrainingProgramRead" },
        { action_id: "TrainingProgramCreate" },
        { action_id: "TrainingProgramUpdate" },
        { action_id: "TrainingProgramDelete" },
        { action_id: "PolicyManagerRead" },
        { action_id: "DocumentRead" },
        { action_id: "CoachRead" },
        { action_id: "CoachCreate" },
        { action_id: "CoachUpdate" },
        { action_id: "CoachDelete" },
        { action_id: "CoachEngagementRead" },
        { action_id: "CoachSessionRead" },
        { action_id: "ResourceRead" },
        { action_id: "ResourceUpdate" },
        { action_id: "ResourceCreate" },
        { action_id: "ResourceDelete" },
        { action_id: "UserRead" },
        { action_id: "UserCreate" },
        { action_id: "UserDelete" },
        { action_id: "UserUpdate" },
        { action_id: "PolicyTemplateRead" },
        { action_id: "PolicyTemplateUpdate" },
        { action_id: "PolicyTemplateCreate" },
        { action_id: "PolicyTemplateDelete" },
        { action_id: "AssessmentTemplateRead" },
        { action_id: "AssessmentTemplateUpdate" },
        { action_id: "AssessmentTemplateCreate" },
        { action_id: "AssessmentTemplateDelete" },
        { action_id: "AuditLogRead" },
        { action_id: "AdminUserRead" },
        { action_id: "AdminUserUpdate" },
        { action_id: "AdminUserCreate" },
        { action_id: "AdminUserDelete" },
        { action_id: "IncidentStakeholderRead" },
        { action_id: "IncidentRead" },
      ],
    },
    menus: {
      connect: [
        { menu_id: "admin_dashboard" },
        { menu_id: "phi_settings" },
        { menu_id: "coach" },
        { menu_id: "clients" },
        { menu_id: "resources" },
        { menu_id: "privacy_community" },
        { menu_id: "audit_logs" },
        { menu_id: "policy_templates" },
        { menu_id: "assessment_templates" },
        { menu_id: "incident_management" },
      ],
    },
  },
  {
    role_name: "Coach",
    role_description: "Level 2",
    role_id: ROLE_IDS[1],
    actions: {
      connect: [
        { action_id: "ManageAllClients" },
        { action_id: "ManageEnterpriseClients" },
        { action_id: "OrganizationRead" },
        { action_id: "OrganizationCreate" },
        { action_id: "OrganizationUpdate" },
        { action_id: "OrganizationDelete" },
        { action_id: "RiskRegistryRead" },
        { action_id: "AssessmentRead" },
        { action_id: "AssessmentCreate" },
        { action_id: "AssessmentUpdate" },
        { action_id: "AssessmentDelete" },
        { action_id: "TrainingProgramRead" },
        { action_id: "TrainingProgramCreate" },
        { action_id: "TrainingProgramUpdate" },
        { action_id: "TrainingProgramDelete" },
        { action_id: "PolicyManagerRead" },
        { action_id: "PolicyManagerCreate" },
        { action_id: "PolicyManagerUpdate" },
        { action_id: "PolicyManagerDelete" },
        { action_id: "DocumentRead" },
        { action_id: "DocumentCreate" },
        { action_id: "DocumentDelete" },
        { action_id: "DocumentUpdate" },
        { action_id: "CoachRead" },
        { action_id: "CoachCreate" },
        { action_id: "CoachUpdate" },
        { action_id: "CoachDelete" },
        { action_id: "CoachEngagementRead" },
        { action_id: "CoachEngagementCreate" },
        { action_id: "CoachEngagementUpdate" },
        { action_id: "CoachEngagementDelete" },
        { action_id: "CoachSessionRead" },
        { action_id: "CoachSessionCreate" },
        { action_id: "CoachSessionUpdate" },
        { action_id: "CoachSessionDelete" },
        { action_id: "ResourceRead" },
        { action_id: "ResourceUpdate" },
        { action_id: "ResourceCreate" },
        { action_id: "ResourceDelete" },
        { action_id: "UserRead" },
        { action_id: "UserCreate" },
        { action_id: "UserDelete" },
        { action_id: "UserUpdate" },
        { action_id: "AdminUserRead" },
        { action_id: "IncidentStakeholderRead" },
        { action_id: "IncidentRead" },
      ],
    },
    menus: {
      connect: [
        { menu_id: "coach_dashboard" },
        { menu_id: "coach" },
        { menu_id: "chat" },
        { menu_id: "resources" },
        { menu_id: "privacy_community" },
        { menu_id: "incident_management" },
      ],
    },
  },
  {
    role_name: "Organization Admin",
    role_description: "Level 3",
    role_id: ROLE_IDS[2],
    actions: {
      connect: [
        { action_id: "OrganizationRead" },
        { action_id: "OrganizationCreate" },
        { action_id: "OrganizationUpdate" },
        { action_id: "OrganizationDelete" },
        { action_id: "RiskRegistryRead" },
        { action_id: "AssessmentRead" },
        { action_id: "AssessmentCreate" },
        { action_id: "AssessmentUpdate" },
        { action_id: "AssessmentDelete" },
        { action_id: "TrainingProgramRead" },
        { action_id: "PolicyManagerRead" },
        { action_id: "PolicyManagerCreate" },
        { action_id: "PolicyManagerUpdate" },
        { action_id: "PolicyManagerDelete" },
        { action_id: "DocumentRead" },
        { action_id: "DocumentCreate" },
        { action_id: "DocumentDelete" },
        { action_id: "DocumentUpdate" },
        { action_id: "CoachRead" },
        { action_id: "CoachEngagementRead" },
        { action_id: "CoachEngagementCreate" },
        { action_id: "CoachEngagementUpdate" },
        { action_id: "CoachEngagementDelete" },
        { action_id: "CoachSessionRead" },
        { action_id: "CoachSessionCreate" },
        { action_id: "CoachSessionUpdate" },
        { action_id: "CoachSessionDelete" },
        { action_id: "ResourceRead" },
        { action_id: "UserRead" },
        { action_id: "UserCreate" },
        { action_id: "UserDelete" },
        { action_id: "UserUpdate" },
        { action_id: "IncidentStakeholderRead" },
        { action_id: "IncidentStakeholderCreate" },
        { action_id: "IncidentStakeholderUpdate" },
        { action_id: "IncidentStakeholderDelete" },
        { action_id: "IncidentRead" },
        { action_id: "IncidentCreate" },
        { action_id: "IncidentUpdate" },
        { action_id: "IncidentDelete" },
      ],
    },
    menus: {
      connect: [
        { menu_id: "dashboard" },
        { menu_id: "risk_registry" },
        { menu_id: "assessments" },
        { menu_id: "training" },
        { menu_id: "policy_manager" },
        { menu_id: "document_library" },
        { menu_id: "coach" },
        { menu_id: "chat" },
        { menu_id: "resources" },
        { menu_id: "privacy_community" },
        { menu_id: "incident_management" },
      ],
    },
  },
  {
    role_name: "Organization User",
    role_description: "Level 4",
    role_id: ROLE_IDS[3],
    actions: {
      connect: [
        { action_id: "AssessmentRead" },
        { action_id: "AssessmentUpdate" },
        { action_id: "PolicyManagerRead" },
        { action_id: "DocumentRead" },
        { action_id: "DocumentCreate" },
        { action_id: "DocumentUpdate" },
        { action_id: "ResourceRead" },
        { action_id: "OrganizationRead" },
      ],
    },
    menus: {
      connect: [
        { menu_id: "dashboard" },
        { menu_id: "assessments" },
        { menu_id: "policy_manager" },
        { menu_id: "document_library" },
        { menu_id: "resources" },
      ],
    },
  },
  {
    role_name: "Enterprise Org Admin",
    role_description: "Level 5",
    role_id: ROLE_IDS[4],
    actions: {
      connect: [{ action_id: "ManageEnterpriseClients" }],
    },
    menus: {
      connect: [
        { menu_id: "dashboard" },
        { menu_id: "risk_registry" },
        { menu_id: "assessments" },
        { menu_id: "chat" },
        { menu_id: "resources" },
        { menu_id: "privacy_community" },
        { menu_id: "incident_management" },
      ],
    },
  },
  {
    role_name: "Auditor",
    role_description: "Level 6",
    role_id: ROLE_IDS[5],
    actions: {
      connect: [
        { action_id: "OrganizationRead" },
        { action_id: "RiskRegistryRead" },
        { action_id: "AssessmentRead" },
        { action_id: "PolicyManagerRead" },
        { action_id: "DocumentRead" },
        { action_id: "UserRead" },
        { action_id: "ManageAllClients" },
      ],
    },
    menus: {
      connect: [
        { menu_id: "risk_registry" },
        { menu_id: "assessments" },
        { menu_id: "policy_manager" },
        { menu_id: "document_library" },
        { menu_id: "clients" },
      ],
    },
  },
];
