import useSWR from "swr";

import { ApiError, ExternalIncidentSettings } from "types/api";

export const useExternalIncidentSettings = (orgId?: string) => {
  const { data, error, mutate } = useSWR<ExternalIncidentSettings, ApiError>(
    orgId
      ? `incident-management/external-submission/settings?organization_id=${orgId}`
      : null
  );

  return {
    incidentSettings: data,
    incidentSettingsError: error,
    isIncidentSettingsLoading: data === undefined && !error,
    mutateincidentSettings: mutate,
  };
};
