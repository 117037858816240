/**
 * Global routes table
 */
export const ROUTES = {
  AUTH_LOGIN: "/auth/login",
  AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
  AUTH_REGISTER: "/auth/register",
  AUTH_RESET_PASSWORD: "/auth/forgot-password-reset",
  AUTH_VERIFY_MFA: "/auth/verify-mfa",

  PLAN_SELECTION: "/plan-selection",
  PAYMENTS: "/payments",

  DASHBOARD: "/dashboard",
  RISK_REGISTRY: "/risk-registry",
  TRAINING: "/training",
  TRAINING_HUMAN_RISK: "/training/risk",
  TRAINING_TRAINING: "/training/training",
  DOCUMENTS: "/documents",
  COACH: "/coach",
  CHAT: "/chat",

  ORGANIZATIONS: "/organizations",

  CLIENTS: "/clients",
  CLIENTS_CREATE: "/clients/create",
  PHI_SETTINGS: "/phi-settings",

  ADMIN_DASHBOARD: "/admin-dashboard",
  COACH_DASHBOARD: "/coach-dashboard",

  RESOURCES: "/resources",
  RESOURCES_CREATE: "/resources/create",

  INCIDENT_MANAGEMENT: "/incident-management",
  INCIDENT_MANAGEMENT_SETTINGS: "/incident-management/settings",

  POLICY_TEMPLATES: "/policy-templates",
  ADMIN_POLICY_CREATE: "/policy-templates/create",

  ASSESSMENT_TEMPLATES: "/assessment-templates",
  CONTROL_TASK_TEMPLATES: "/controls",
  CONTROL_TASK_TEMPLATE_CREATE: "/controls/create",

  POLICIES: "/policies",
  POLICY_CREATE: "/policies/create",

  ASSESSMENTS: "/assessments",
  ASSESSMENT_CREATE: "/assessments/create",

  UNAUTHORIZED: "/unauthorized",
  PROFILE_USER: "/profile/user",
  PROFILE_ORGANIZATION: "/profile/organization",
  COMMUNITY: "/community",

  NOT_FOUND: "/404",
} as const;
