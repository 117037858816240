import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, ControlTask } from "types/api";

export const useRisks = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<ControlTask[], ApiError>(
    organizationId ? `organization/${organizationId}/risk-registry` : null
  );

  return {
    risks: data,
    risksError: error,
    isRisksLoading: !data && !error,
    mutateRisks: mutate,
  };
};
