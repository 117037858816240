import useSWR from "swr";

import { ApiError, Resource } from "types/api";

export const useResources = () => {
  const { data, error, mutate } = useSWR<Resource[], ApiError>("resource");

  return {
    resources: data,
    resourcesError: error,
    isresourcesLoading: !data && !error,
    mutateResources: mutate,
  };
};
