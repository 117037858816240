export enum BreachIncidentType {
  Incident = "Incident",
  Breach = "Breach",
}

interface BreachIncidentSubcategory {
  breach_incident_subcategory_id: string;
  breach_incident_category_id: string;
  subcategory_name: string;
  order: number;
  created_at: Date;
  updated_at: Date;
}

interface BreachIncidentExample {
  breach_incident_example_id: string;
  breach_incident_category_id: string;
  breach_incident_example_description: string;
  order: number;
  created_at: Date;
  updated_at: Date;
}

export interface BreachIncidentCategory {
  breach_incident_category_id: string;
  breach_incident_category_name: string;
  breach_incident_category_description: string;
  breach_incident_type: BreachIncidentType;
  order: number;
  created_at: Date;
  updated_at: Date;
  breach_incident_subcategories: BreachIncidentSubcategory[];
  breach_incident_examples: BreachIncidentExample[];
}
