import useSWR from "swr";

import { ApiError, StripeBillingInfo } from "types/api";

export const useBillingData = (organizationId?: string[] | string | null) => {
  const { data, error, mutate } = useSWR<StripeBillingInfo, ApiError>(
    typeof organizationId === "string"
      ? `organization/${organizationId}/billing`
      : null
  );

  return {
    billingData: data,
    billingDataError: error,
    isBillingDataLoading: !data && !error,
    mutatePlan: mutate,
  };
};
