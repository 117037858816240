import useSWR from "swr";

import { ApiError, Engagement } from "types/api";

export const useEngagementsForOrganization = (
  organizationId?: string[] | string | null
) => {
  const { data, error, mutate } = useSWR<Engagement[], ApiError>(
    typeof organizationId === "string"
      ? `coach/organization/${organizationId}/engagement`
      : null
  );

  return {
    engagements: data,
    engagementsError: error,
    isEngagementsLoading: !data && !error,
    mutateEngagements: mutate,
  };
};
