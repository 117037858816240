import {
  BreachIncidentCategory,
  BreachIncidentCategoryRecord,
  BreachIncidentExample,
  BreachIncidentSubcategory,
  BreachIncidentSubcategoryOtherRecord,
  BreachIncidentSubcategoryRecord,
  Incident,
  IncidentReportActionItemAdditionalResponse,
  IncidentReportActionItemResponse,
  IncidentSettings,
  IncidentStakeholder,
  Organization,
  Prisma,
  User,
} from "@prisma/client";

import { ApiPostSuccess } from "./base";
import { BreachIncidentType } from "./breach-incident-category";
import { IncidentStakeholderWithRole } from "./incident-stakeholder";

export type IncidentCreateParams = Omit<
  Prisma.IncidentUncheckedCreateWithoutBreach_incident_category_recordsInput,
  | "assigned_user_id"
  | "created_at"
  | "incident_id"
  | "incident_number"
  | "incident_report_id"
  | "IncidentReportActionItemResponse"
  | "updated_at"
>;

export type ExternalIncidentCreateParams = {
  values: Omit<
    Prisma.IncidentUncheckedCreateWithoutBreach_incident_category_recordsInput,
    | "assigned_user_id"
    | "created_at"
    | "incident_id"
    | "incident_number"
    | "incident_report_id"
    | "IncidentReportActionItemResponse"
    | "organization_id"
    | "updated_at"
  >;
  customDomain: string;
};

export interface IncidentWithCategoriesCreateParams
  extends IncidentCreateParams {
  categories: IntakeFormIncidentCheckCategory[];
}

export type IncidentUpdateParams = Omit<
  IncidentCreateParams,
  "anonymous" | "created_at" | "incident_id" | "updated_at"
> &
  Partial<Pick<Incident, "assigned_user_id">> &
  Pick<Incident, "incident_number" | "incident_report_id"> & {
    categories?: IntakeFormIncidentCheckCategory[];
  };

export type UpdateIncidentIntakeFormValues = {
  first_name: string;
  last_name: string;
  title: string;
  department?: string;
  phone_number?: string;
  email?: string;
  incident_reported: Date;
  incident_discovered: Date;
  incident_occurred: Date;
  location_of_incident: string;
  incident_description: string;
};

export type IncidentCreateResponse = ApiPostSuccess<{
  incident_id: string;
  incident_report_id: string;
}>;
export type IncidentUpdateResponse = ApiPostSuccess<{
  incident_report_id: string;
}>;
export type IncidentDeleteResponse = ApiPostSuccess<{
  incident_id: string;
}>;

export type BreachIncidentCategoryRecordWithSubcategory =
  BreachIncidentCategoryRecord & {
    breach_incident_category: BreachIncidentCategory & {
      breach_incident_examples: BreachIncidentExample[];
    };
    breach_incident_subcategory_records: (BreachIncidentSubcategoryRecord & {
      breach_incident_subcategory: BreachIncidentSubcategory;
      breach_incident_subcategory_other_record: BreachIncidentSubcategoryOtherRecord | null;
    })[];
  };

export type IncidentType = Incident & {
  breach_incident_category_records?: BreachIncidentCategoryRecordWithSubcategory[];
  IncidentReportActionItemResponse?: (IncidentReportActionItemResponse & {
    incident_report_action_item_additional_response?: (IncidentReportActionItemAdditionalResponse & {
      incident_stakeholders?: IncidentStakeholder | null;
    })[] & {
      author?: User | null;
    };
    author?: User | null;
  })[];
  author?: User | null;
  assigned_user?: User | null;
  incident_settings?: IncidentSettings | null;
  organization?: Organization | null;
};

export interface IntakeFormIncidentValues {
  first_name: string;
  last_name: string;
  title: string;
  department: string;
  phone_number: string;
  email: string;
  incident_reported: Date;
  incident_discovered: Date;
  incident_occurred: Date;
  location_of_incident: string;
  incident_description: string;
  categories: IntakeFormIncidentCheckCategory[];
  anonymous?: boolean;
}

export type ExternalIntakeFormValues = Omit<
  IntakeFormIncidentValues,
  "categories"
>;

export interface IntakeFormIncidentCheckSubcategory {
  value: string;
  title: string;
  primary: boolean;
  other_value?: string;
}

export interface IntakeFormIncidentCheckCategory {
  value: string;
  title: string;
  description?: string[];
  examples?: string[];
  primary: boolean;
  severity: number;
  subcategories: IntakeFormIncidentCheckSubcategory[];
}

export type GetActionItemParams = {
  incident_report_id: string;
};

export type IncidentReportActionItemBody = Omit<
  Prisma.IncidentReportActionItemResponseUncheckedCreateInput,
  | "created_at"
  | "id"
  | "incident_report_action_item_additional_response"
  | "incident_report_action_item_response_id"
  | "incident_settings_id"
  | "updated_at"
>;

export type IncidentReportActionAdditionalItemBody = Omit<
  Prisma.IncidentReportActionItemAdditionalResponseUncheckedCreateInput,
  | "created_at"
  | "id"
  | "incident_report_action_item_additional_response_id"
  | "incident_report_action_item_response_id"
  | "incident_settings_id"
  | "updated_at"
>;

export type ActivityLogSubcategory = {
  name?: string;
  primary?: boolean;
  other_value?: string;
};

export type ActivityLogCategory = {
  name: string;
  author: string;
  primary?: boolean;
  severity?: number;
  created_at?: Date;
  subcategories: ActivityLogSubcategory[];
};

export type ActivityLogIntake = {
  first_name?: string;
  last_name?: string;
  email?: string;
  title?: string;
  department?: string;
  incident_reported?: Date;
  incident_discovered?: Date;
  incident_occurred?: Date;
  location?: string;
  description?: string;
  type: BreachIncidentType;
  assigned_user?: string;
  completed_at?: Date;
};

export type ActivityLogActionItem = {
  measure_taken?: string;
  summary?: string;
  number_of_affected_individuals?: number;
  root_cause?: string;
  complete?: boolean;
  type: string;
};

export type ActivityLogAdditionalActionItem = {
  id: string;
  occurrance_date?: Date;
  who?: string;
  who_text?: string;
  note?: string;
  action_name?: string;
  target_completion_date?: Date;
  comments?: string;
};

export enum ActivityLogType {
  Intake = "Intake",
  Triage = "Triage",
  ActionItem = "ActionItem",
  AdditionalActionItem = "AdditionalActionItem",
}

export type TriageDiff = {
  oldValuesTriage: ActivityLogCategory | null;
  newValuesTriage: ActivityLogCategory | null;
};

export type IntakeDiff = {
  oldValuesIntake: ActivityLogIntake | null;
  newValuesIntake: ActivityLogIntake | null;
};

export type ActionItemDiff = {
  oldActionItem: ActivityLogActionItem | null;
  newActionItem: ActivityLogActionItem | null;
};

export type AdditionalActionItemDiff = {
  oldAdditionalActionItem: ActivityLogAdditionalActionItem | null;
  newAdditionalActionItem: ActivityLogAdditionalActionItem | null;
};

export type StringDiff = {
  oldValueString: string;
  newValueString: string;
};

export type IncidentActivityLogItemValues = {
  summary: string;
  date: string;
  actionItemDiff?: ActionItemDiff;
  intakeDiff?: IntakeDiff;
  triageDiff?: TriageDiff[];
  additionalActionItemDiff?: AdditionalActionItemDiff[];
  type: ActivityLogType;
};

export type IncidentReportActionItemWithAdditionalResponse =
  IncidentReportActionItemResponse & {
    incident_report_action_item_additional_response: (IncidentReportActionItemAdditionalResponse & {
      incident_stakeholders?: IncidentStakeholder | null;
    })[];
  };

export type IncidentReportActionItemAdditionalFormValues = {
  who?: string;
  who_text?: string;
  note?: string;
  action_name?: string;
  comments?: string;
  target_completion_date?: Date | null;
  active: boolean;
  occurance_date?: Date | null;
  order_date?: Date | null;
  incident_report_action_item_additional_response_persistant_id?: string | null;
};

export type IncidentReportActionItemFormValues = {
  type: string;
  measure_taken?: string | null;
  summary?: string | null;
  number_of_affected_individuals?: number | null;
  root_cause?: string | null;
  actions: IncidentReportActionItemAdditionalFormValues[];
  complete: boolean;
  active: boolean;
};

export type ActionItemsType = {
  containment?: IncidentReportActionItemWithAdditionalResponse;
  investigation?: IncidentReportActionItemWithAdditionalResponse;
  communication?: IncidentReportActionItemWithAdditionalResponse;
  mandatoryNotification?: IncidentReportActionItemWithAdditionalResponse;
  remediation?: IncidentReportActionItemWithAdditionalResponse;
};

export type BreachReportPDFData = {
  incident: IncidentType;
  isIncident: boolean;
  actionItems: ActionItemsType;
  organizationName: string;
};

export type SummaryReportPDFData = {
  incident: IncidentType;
  isIncident: boolean;
  actionItems: ActionItemsType;
  organizationName: string;
  incidentStakeholders: IncidentStakeholderWithRole[];
};

export type CategoryGroupedData = {
  name: string;
  severity: string;
  cost: string[];
  privacy: string[];
  reputation: string[];
};
