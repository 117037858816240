import useSWR from "swr";

import { ApiError, ExternalOrganization } from "types/api";

export const useExternalOrganization = (
  organizationId?: string | null | undefined
) => {
  const { data, error, mutate } = useSWR<ExternalOrganization, ApiError>(
    organizationId
      ? `incident-management/external-submission/organization?organization_id=${organizationId}`
      : null
  );
  return {
    organization: data,
    organizationError: error,
    isOrganizationLoading: !data && !error,
    mutateOrganization: mutate,
  };
};
