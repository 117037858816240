import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, IncidentStakeholderWithRole } from "types/api";

export const useIncidentStakeholders = () => {
  const { organizationId } = useAuth();

  const { data, error, mutate } = useSWR<
    IncidentStakeholderWithRole[],
    ApiError
  >(
    organizationId ? `incident-management/${organizationId}/stakeholder` : null
  );

  return {
    incidentStakeholders: data,
    incidentStakeholdersError: error,
    isIncidentStakeholdersLoading: !data && !error,
    mutateincidentStakeholders: mutate,
  };
};
