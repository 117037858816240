import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  SkeletonProps,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import { ApiError } from "types/api";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: theme.shape.borderRadius,
    transform: "none",
  },
}));

export const SkeletonLoader: React.FC<
  SkeletonProps & { loading: boolean; error?: ApiError }
> = ({
  loading,
  children,
  animation = "wave",
  width = "100%",
  error,
  ...props
}) => {
  const classes = useStyles();

  if (error) {
    return (
      <Card>
        <CardHeader title={error.error} />
        <CardContent>
          <Typography variant="body2">{error.message}</Typography>
        </CardContent>
      </Card>
    );
  }

  if (loading) {
    return (
      <Skeleton
        width={width}
        animation={animation}
        className={classes.skeleton}
        {...props}
      >
        {children}
      </Skeleton>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
