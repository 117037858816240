import useSWR from "swr";

import { ApiError, UserWithRoles } from "types/api";

export const useEnterpriseAdminUsers = () => {
  const { data, error, mutate } = useSWR<UserWithRoles[], ApiError>(
    `admin/user/enterprise`
  );

  return {
    enterpriseUsers: data,
    enterpriseUsersError: error,
    isEnterpriseUsersLoading: !data && !error,
    mutateEnterpriseUsers: mutate,
  };
};
