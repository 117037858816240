import useSWR from "swr";

import { SessionWithOrganizationName } from "pages/api/session";

import { ApiError, Session } from "types/api";

export const useSessionsCreatedByUser = (userId?: string[] | string | null) => {
  const { data, error, mutate } = useSWR<
    SessionWithOrganizationName[],
    ApiError
  >(typeof userId === "string" ? `coach/session/created-by/${userId}` : null);

  return {
    sessions: data,
    sessionsError: error,
    isSessionsLoading: !data && !error,
    mutateSessions: mutate,
  };
};

export const useSessionsForOrganization = (
  organizationId?: string[] | string | null
) => {
  const { data, error, mutate } = useSWR<Session[], ApiError>(
    typeof organizationId === "string"
      ? `coach/organization/${organizationId}/session`
      : null
  );

  return {
    sessions: data,
    sessionsError: error,
    isSessionsLoading: !data && !error,
    mutateSessions: mutate,
  };
};
