import useSWR from "swr";

import { ApiError, OrganizationWithPayment } from "types/api";

export const useOrganization = (organizationId?: string | null | undefined) => {
  const { data, error, mutate } = useSWR<OrganizationWithPayment, ApiError>(
    organizationId ? `organization/${organizationId}` : null
  );

  return {
    organization: data,
    organizationError: error,
    isOrganizationLoading: !data && !error,
    mutateOrganization: mutate,
  };
};
