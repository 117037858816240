import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, IncidentSettings } from "types/api";

export const useIncidentSettings = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<IncidentSettings, ApiError>(
    organizationId ? `incident-management/${organizationId}/settings` : null
  );

  return {
    incidentSettings: data,
    incidentSettingsError: error,
    isIncidentSettingsLoading: data === undefined && !error,
    mutateincidentSettings: mutate,
  };
};
