import { useRouter } from "next/router";
import * as React from "react";

import { ROLE_IDS } from "../../../seeds/data/role";

import ExtraPaymentSplashScreen from "components/ExtraPaymentSplashScreen";
import Loading from "components/Loading";
import RegisterSplashScreen from "components/RegisterSplashScreen";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import { usePendingPaymentForOrg } from "requests/queries";

export const AuthenticatedRoute: React.FC = ({ children }) => {
  const auth = useAuth();
  const router = useRouter();
  const { pendingPayment, isPendingPaymentLoading } = usePendingPaymentForOrg(
    auth.user?.organization_id
  );

  if (
    !auth.isInitialized ||
    auth.subscription === "loading" ||
    (isPendingPaymentLoading && auth.isAuthenticated)
  )
    return <Loading open />;

  if (!auth.isAuthenticated) router.push(ROUTES.AUTH_LOGIN);

  if (
    auth.roles.every(
      (role) =>
        role.role_id !== ROLE_IDS[0] &&
        role.role_id !== ROLE_IDS[1] &&
        role.role_id !== ROLE_IDS[4]
    ) &&
    !auth.subscription?.pay_on_account &&
    (!auth.subscription?.current_period_end ||
      auth.subscription.current_period_end * 1000 < Date.now())
  ) {
    return <RegisterSplashScreen logout={auth.logout} />;
  }

  if (pendingPayment && !pendingPayment.pay_on_account)
    return <ExtraPaymentSplashScreen logout={auth.logout} />;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
