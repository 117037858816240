import useSWR from "swr";

import { axiosClient } from "clients/axios";
import useAuth from "hooks/useAuth";

import { ApiError, User, UserWithRoles } from "types/api";

export const useUsers = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<UserWithRoles[], ApiError>(
    organizationId ? `organization/${organizationId}/user` : null
  );

  return {
    users: data,
    usersError: error,
    isUsersLoading: !data && !error,
    mutateUsers: mutate,
  };
};

export const useActiveUsers = (proxyOrganizationId: string | null = null) => {
  const { organizationId } = useAuth();
  const targetOrganizationId = proxyOrganizationId
    ? proxyOrganizationId
    : organizationId;
  const { data, error, mutate } = useSWR<UserWithRoles[], ApiError>(
    targetOrganizationId
      ? `organization/${targetOrganizationId}/user/active`
      : null
  );

  return {
    users: data,
    usersError: error,
    isUsersLoading: !data && !error,
    mutateUsers: mutate,
  };
};

export const useOrganizationsActiveUsers = async (
  unPaidOrganizaitionIds: string[]
) => {
  const response = await axiosClient.get<
    {
      orgID: string;
      users: User[];
    }[]
  >(`organization/organizations_ids/user/active`, {
    params: { orgsIds: unPaidOrganizaitionIds },
  });

  return {
    users: response.data,
    usersError: false,
    isUsersLoading: false,
  };
};
