import { PendingPayment } from "@prisma/client";
import useSWR from "swr";

import { ApiError } from "types/api";

export const usePendingPaymentForOrg = (organizationId?: string | null) => {
  const { data, error, mutate } = useSWR<PendingPayment, ApiError>(
    organizationId ? `payment/pending/${organizationId}` : null
  );

  return {
    pendingPayment: data,
    pendingPaymentError: error,
    isPendingPaymentLoading: data === undefined && !error,
    mutatePendingPayment: mutate,
  };
};
