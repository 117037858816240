import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, IncidentType } from "types/api";

export const useIncident = (incidentVersionId?: string | null) => {
  const { organizationId } = useAuth();

  const { data, error, mutate } = useSWR<IncidentType, ApiError>(
    organizationId && incidentVersionId
      ? `incident-management/${organizationId}/incident/${incidentVersionId}`
      : null
  );

  return {
    incident: data,
    incidentError: error,
    isIncidentLoading: !data && !error,
    mutateIncident: mutate,
  };
};
