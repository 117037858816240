import useSWR from "swr";

import { ApiError, Plan } from "types/api";

export const usePlans = () => {
  const { data, error, mutate } = useSWR<Plan[], ApiError>("payment/plan");

  return {
    plans: data,
    plansError: error,
    isPlansLoading: !data && !error,
    mutatePlans: mutate,
  };
};
