import useSWR from "swr";

import { ApiError, SubscriptionCycles } from "types/api";

export const useSubscriptionCycles = (
  organizationId?: string[] | string | null
) => {
  const { data, error } = useSWR<SubscriptionCycles, ApiError>(
    typeof organizationId === "string"
      ? `organization/${organizationId}/subscription-cycles`
      : null
  );

  return {
    subscriptionCycles: data,
    subscriptionCyclesError: error,
    isSubscriptionCyclesLoading: !data && !error,
  };
};
