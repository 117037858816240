import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, Document } from "types/api";

export const useDocuments = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<Document[], ApiError>(
    organizationId ? `organization/${organizationId}/document` : null
  );

  return {
    documents: data,
    documentsError: error,
    isDocumentsLoading: !data && !error,
    mutateDocuments: mutate,
  };
};
