import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import { PendingPayment } from "@prisma/client";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import type { FC } from "react";
import useSWR from "swr";

import { SkeletonLoader } from "./SkeletonLoader";

import { ADDON_IDS } from "backend/constants/add-on-ids";
import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import { AddOnValues } from "pages/plan-selection";
import {
  usePendingPaymentForOrg,
  useSubscriptionPlansIncludeScheduled,
} from "requests/queries";
import { useAddons } from "requests/queries/useAddons";

import { ApiError, PlanData } from "types/api";

const ExtraPaymentSplashScreen: FC<{ logout: () => Promise<void> }> = ({
  logout,
}) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId } = useAuth();
  const { data: planData, error: planDataError } = useSWR<PlanData, ApiError>(
    organizationId ? `organization/${organizationId}/plan` : null
  );
  const { addons, isAddonsLoading } = useAddons();
  const { subscriptionPlans, isSubscriptionPlansLoading } =
    useSubscriptionPlansIncludeScheduled(organizationId);

  const scheduledSubscriptionValues = useMemo(() => {
    if (!subscriptionPlans || !addons) return {};

    const subscriptionItems =
      subscriptionPlans.scheduled_items ?? subscriptionPlans.items;

    return subscriptionItems.reduce(
      (prev: Partial<AddOnValues>, curr) => {
        switch (curr.product.id) {
          case addons.find((addOn) => addOn.addon_id === ADDON_IDS.phiShield)
            ?.stripe_product_id:
            prev.phiShieldLevel = curr.quantity > 0 ? "phi_shield" : "";
            prev.phiShieldSeats = curr.quantity;
            break;

          default:
        }

        return prev;
      },
      {
        phiShieldLevel: "",
      }
    );
  }, [addons, subscriptionPlans]);

  const getPlanUpgradeData = (
    pendingPayment: PendingPayment,
    pd: PlanData,
    sv: Partial<AddOnValues>
  ) => {
    const obj = {
      old_plan_id: pd.id,
      coaching_hours: pendingPayment.coaching_hours,
      training_seats: pendingPayment.training_seats,
      old_phi_shield_level: sv.phiShieldLevel ?? "",
      phi_shield_level: pendingPayment.phi_shield_level,
      phi_shield_seats: pendingPayment.phi_shield_seats,
      upgrading: true,
      due_now: pendingPayment.due_now,
      im_addon: pendingPayment.im_addon,
      soc2_type1: pendingPayment.soc2_type1_expiry ? true : undefined,
      soc2_type2: pendingPayment.soc2_type2_expiry ? true : undefined,
      soc2_assessment: pendingPayment.soc2_assessment_expiry ? true : undefined,
      pending_payment_id: pendingPayment.pending_payment_id,
    };
    return obj;
  };
  const getData = (pendingPayment: PendingPayment) => {
    const obj = {
      non_recurring_hours: pendingPayment.non_recurring_hours,
      training_seats: pendingPayment.training_seats,
      pending_payment_id: pendingPayment.pending_payment_id,
      old_plan_id: pendingPayment.plan_id,
    };

    if (
      pendingPayment.training_seats > 0 &&
      pendingPayment.non_recurring_hours === 0
    ) {
      // @ts-expect-error TS gets confused about adding new entries to object
      obj.upgrading = "true";
      // @ts-expect-error TS gets confused about adding new entries to object
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      obj.due_now = pendingPayment.due_now;
    }

    return obj;
  };
  const loadingUpgradeData =
    isAddonsLoading ||
    isSubscriptionPlansLoading ||
    (!planDataError && !planData);

  const { pendingPayment, isPendingPaymentLoading } =
    usePendingPaymentForOrg(organizationId);

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      router.push(ROUTES.AUTH_LOGIN);
    } catch (err: unknown) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      }}
    >
      <Card>
        <CardHeader title="Access Error" />
        <Divider />
        <CardContent>
          <SkeletonLoader loading={isPendingPaymentLoading}>
            <Box sx={{ mt: 2 }}>
              <Typography color="textSecondary" variant="subtitle2">
                <div style={{ padding: 12 }}>
                  Payment must be completed to continue.
                </div>
              </Typography>
              {pendingPayment?.admin_plan_upgrade && loadingUpgradeData && (
                <LoadingButton
                  color="secondary"
                  fullWidth
                  size="large"
                  variant="contained"
                  loading
                >
                  Go To Payments
                </LoadingButton>
              )}
              {pendingPayment?.admin_plan_upgrade &&
                !loadingUpgradeData &&
                planData && (
                  <Link
                    href={{
                      pathname: `${ROUTES.PAYMENTS}/${pendingPayment.plan_id}`,
                      query: getPlanUpgradeData(
                        pendingPayment,
                        planData,
                        scheduledSubscriptionValues
                      ),
                    }}
                    passHref
                  >
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      Go To Payments
                    </Button>
                  </Link>
                )}
              {pendingPayment && !pendingPayment.admin_plan_upgrade && (
                <Link
                  href={{
                    pathname: `${ROUTES.PAYMENTS}/${pendingPayment.plan_id}`,
                    query: getData(pendingPayment),
                  }}
                  passHref
                >
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    Go To Payments
                  </Button>
                </Link>
              )}
              <Box paddingTop="16px">
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleLogout}
                >
                  Log Out
                </Button>
              </Box>
            </Box>
          </SkeletonLoader>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExtraPaymentSplashScreen;
