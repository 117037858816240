import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, Policy } from "types/api";

export const usePolicy = (policyId?: string | null) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<Policy, ApiError>(
    policyId && organizationId
      ? `organization/${organizationId}/policy/${policyId}`
      : null
  );

  return {
    policy: data,
    policyError: error,
    isPolicyLoading: !data && !error,
    mutatePolicy: mutate,
  };
};
