import useSWR from "swr";

import { ApiError, SubscriptionPlanInfo } from "types/api";

export const useSubscriptionPlans = (
  organizationId?: string[] | string | null
) => {
  const { data, error } = useSWR<SubscriptionPlanInfo, ApiError>(
    typeof organizationId === "string"
      ? `organization/${organizationId}/subscription-plan`
      : null
  );

  return {
    subscriptionPlans: data,
    subscriptionPlansError: error,
    isSubscriptionPlansLoading: !data && !error,
  };
};

export const useSubscriptionPlansIncludeScheduled = (
  organizationId?: string[] | string | null
) => {
  const { data, error } = useSWR<SubscriptionPlanInfo, ApiError>(
    typeof organizationId === "string"
      ? `organization/${organizationId}/subscription-plan/scheduled`
      : null
  );

  return {
    subscriptionPlans: data,
    subscriptionPlansError: error,
    isSubscriptionPlansLoading: !data && !error,
  };
};
