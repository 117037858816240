import { HowItWorks } from "@prisma/client";
import useSWR from "swr";

import { ApiError } from "types/api";

export const useWorks = (title: string) => {
  const { data } = useSWR<HowItWorks, ApiError>(
    title ? `works/${title}` : null
  );

  return {
    data,
  };
};
