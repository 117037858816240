import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError } from "types/api";

export const useAssessmentControlIds = (
  assessmentId?: string[] | string | null
) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<string[], ApiError>(
    typeof assessmentId === "string" && organizationId
      ? `organization/${organizationId}/assessment/${assessmentId}/control/ids`
      : null
  );

  return {
    controlIds: data,
    controlIdsError: error,
    isControlIdsLoading: !data && !error,
    mutateControlIds: mutate,
  };
};
