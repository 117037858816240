import { Container, Grid, Typography } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import { Breakpoint, SxProps } from "@mui/system";
import { Routes } from "@prisma/client";
import { useRouter } from "next/router";
import React from "react";

import { ROLE_IDS } from "../../../seeds/data/role";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

import { AuthenticatedRoute } from "components/authentication/AuthenticatedRoute";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import { useSubscriptionPlans } from "requests/queries";

import { STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME } from "types/api";

const DashboardLayoutRoot = experimentalStyled("div")(() => ({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: "64px",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "280px",
  },
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  width: "100%",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout: React.FC<{
  title: string;
  sx?: SxProps;
  maxWidth?: Breakpoint;
}> = ({ children, title, sx, maxWidth }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    React.useState<boolean>(false);
  const [isPermitted, setIsPermitted] = React.useState<boolean>(false);
  const router = useRouter();
  const auth = useAuth();
  const { subscriptionPlans } = useSubscriptionPlans(auth.organizationId);

  function replaceBetween(stringToModify: string, startIndex: number) {
    return (
      stringToModify.substring(0, startIndex - 1) +
      stringToModify.substring(stringToModify.length)
    );
  }

  const isBasicPlan = React.useMemo(() => {
    if (!subscriptionPlans) return true;

    const trainingSeats =
      (subscriptionPlans.items.find(
        (plan) => plan.product.name === "Training Seat"
      )?.quantity ?? 0) +
      (subscriptionPlans.items[0].db_product?.training_seats ?? 0);

    if (trainingSeats === 0) return true;

    return false;
  }, [subscriptionPlans]);

  const isIncidentManagementPlan = React.useMemo(() => {
    if (!subscriptionPlans) return false;
    const incidentManagementPlan = subscriptionPlans.items.find(
      (plan) => plan.product.name === STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME
    );

    return Boolean(incidentManagementPlan);
  }, [subscriptionPlans]);

  const hasIncidentManagementAddon = React.useMemo(() => {
    if (!subscriptionPlans) return false;
    const imAddon = subscriptionPlans.items.find(
      (plan) =>
        plan.product.name === "Incident Management Add-On" && plan.quantity > 0
    );

    const imAddonIncludedPlan = subscriptionPlans.items.find(
      (plan) =>
        plan.product.name === STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME ||
        plan.product.name === "VPO"
    );

    return Boolean(imAddon) || Boolean(imAddonIncludedPlan);
  }, [subscriptionPlans]);
  React.useEffect(() => {
    if (router.pathname !== "/") {
      Object.entries(ROUTES).forEach((route) => {
        // Base case
        if (
          auth.routes.includes(route[0] as Routes) &&
          !router.pathname.includes("[")
        ) {
          if (route[1] === router.pathname) {
            if (auth.routes.includes(route[0] as Routes)) {
              setIsPermitted(true);
            }
          }
        }
        // Case for where there is route parameters, such as a UUID that represents a record to fetch
        if (router.pathname.includes("[") && router.pathname.includes("[")) {
          const sanitizedRoute = router.pathname;
          const startStrip = sanitizedRoute.indexOf("[");
          const strippedRoute = replaceBetween(sanitizedRoute, startStrip);

          if (route[1] === strippedRoute) {
            if (auth.routes.includes(route[0] as Routes)) {
              setIsPermitted(true);
            }
          }
        }
      });
    } else {
      setIsPermitted(true);
      return;
    }

    const basePath = router.pathname.split("/")[1] ?? "";

    if (basePath === "training" || basePath === "policies") {
      if (isBasicPlan) setIsPermitted(false);
    }

    if (
      (hasIncidentManagementAddon || isIncidentManagementPlan) &&
      (basePath === "incident-management" || basePath === "profile")
    ) {
      if (
        basePath === "incident-management" &&
        !auth.routes.includes("INCIDENT_MANAGEMENT")
      ) {
        setIsPermitted(false);
      } else {
        setIsPermitted(true);
      }
    } else if (basePath === "incident-management") {
      setIsPermitted(false);
    }

    if (
      basePath === "incident-management" &&
      process.env.NEXT_PUBLIC_INCIDENT_TESTING_MODE !== "true"
    ) {
      setIsPermitted(false);
    }

    // Auditor specific permissions
    if (auth.roles.some((r) => r.role_id === ROLE_IDS[5])) {
      // Auditors can view the base clients path, but not the drilldown
      if (basePath === "clients" && router.pathname.includes("["))
        setIsPermitted(false);
    }
  }, [router, auth, isBasicPlan, isIncidentManagementPlan]);

  return (
    <AuthenticatedRoute>
      <title>{title} | Privacy Horizon</title>
      <DashboardLayoutRoot>
        <DashboardNavbar
          onSidebarMobileOpen={(): void => {
            setIsSidebarMobileOpen(true);
          }}
        />
        <DashboardSidebar
          onMobileClose={(): void => {
            setIsSidebarMobileOpen(false);
          }}
          openMobile={isSidebarMobileOpen}
        />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent sx={{ p: 3, pb: 6, ...sx }}>
              <Container
                disableGutters={!maxWidth}
                maxWidth={maxWidth ?? false}
                sx={{ height: "100%" }}
              >
                {isPermitted ? (
                  children
                ) : (
                  <Grid container spacing={3}>
                    <Grid
                      alignItems="center"
                      container
                      item
                      justifyContent="space-between"
                      spacing={3}
                      xs={12}
                    >
                      <Grid item>
                        <Typography color="textSecondary" variant="overline">
                          Unauthorized
                        </Typography>
                      </Grid>
                    </Grid>
                    <div
                      style={{
                        position: "absolute",
                        height: 30,
                        width: 30,
                        backgroundColor: "red",
                        bottom: 0,
                        right: 0,
                        borderTopLeftRadius: 10,
                      }}
                    />
                  </Grid>
                )}
              </Container>
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    </AuthenticatedRoute>
  );
};

export default DashboardLayout;
