import { USER_AVATAR_LIMIT } from "constants/pagination-limits";
import { useSWRPaginate } from "hooks/useSWRPaginate";

import { ApiError, UserAvatar } from "types/api";

export const useUserAvatars = (organizationId: string, userIds: string[]) => {
  const encodedUserIds = encodeURIComponent(JSON.stringify(userIds));

  const url = `organization/${organizationId}/user/avatar?users=${encodedUserIds}`;

  const { data, error, mutate } = useSWRPaginate<UserAvatar, ApiError>({
    url,
    limit: USER_AVATAR_LIMIT,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    userAvatars: data,
    userAvatarsError: error,
    isUserAvatarsLoading: !data && !error,
    mutateUserAvatars: mutate,
  };
};
