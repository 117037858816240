import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, Policy } from "types/api";

export const usePolicies = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<Policy[], ApiError>(
    organizationId ? `organization/${organizationId}/policy` : null
  );

  return {
    policies: data,
    policiesError: error,
    isPoliciesLoading: !data && !error,
    mutatePolicies: mutate,
  };
};
