import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import type { AppBarProps } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import Image from "next/image";
import type { FC } from "react";

import AccountPopover from "components/Popover/AccountPopover";

import useScript from "hooks/useScript";
import MenuIcon from "icons/Menu";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  loading?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = ({
  onSidebarMobileOpen,
  ...other
}) => {
  // Hubspot chat widget
  useScript("//js.hs-scripts.com/5186921.js", "hs-script-loader");

  return (
    <Box displayPrint="none">
      <DashboardNavbarRoot {...other}>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            sx={{ display: { lg: "none", xs: "block" } }}
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          <Box>
            <Image
              alt="PHI Logo"
              src="/logos/phi-logo.svg"
              height={42}
              width={230}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />

          <Box sx={{ ml: 2 }}>
            <AccountPopover />
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>
    </Box>
  );
};

export default DashboardNavbar;
