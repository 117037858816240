import useSWR from "swr";

import { ApiError, PolicyTemplate } from "types/api";

export const usePolicyTemplate = (policyTemplateId?: string | null) => {
  const { data, error, mutate } = useSWR<PolicyTemplate, ApiError>(
    policyTemplateId ? `policy/${policyTemplateId}` : null
  );

  return {
    policyTemplate: data,
    policyTemplateError: error,
    isPolicyTemplateLoading: !data && !error,
    mutatePolicyTemplate: mutate,
  };
};
