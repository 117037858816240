import { Box, Divider, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MenuId } from "@prisma/client";
import Image from "next/image";
import { useRouter } from "next/router";
import * as React from "react";

import { ROLE_IDS } from "../../../seeds/data/role";
import { ViewingCard } from "./ViewingCard";

import { NavItem } from "components/NavItem";
import { NavCollection } from "components/NavSection";
import Scrollbar from "components/Scrollbar";

import {
  sidebarRoutes,
  sidebarRouteOrganized,
  enterpriseSidebarRoutes,
  auditorSidebarRoutes,
  auditorViewAsSidebarRoutes,
} from "constants/sidebar-routes";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import { useOrganization, useSubscriptionPlans } from "requests/queries";

import { STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME } from "types/api";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: React.FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const { menus, organizationId, user, roles } = useAuth();
  const router = useRouter();
  const theme = useTheme();

  const isPhiAdmin = roles.some((role) => role.role_name === "PHI Admin");
  const { settings } = useSettings();
  const { organization } = useOrganization(organizationId);
  const { subscriptionPlans } = useSubscriptionPlans(organizationId);

  const isActive = (pathname: string, itemPath?: string) => {
    if (!itemPath) {
      return false;
    }

    return `${pathname}/`.startsWith(`${itemPath}/`);
  };

  const seeOrgSettings = roles.some((elem) => elem.role_id === ROLE_IDS[2]);
  const isEnterpriseAdmin = roles.some((role) => role.role_id === ROLE_IDS[4]);
  const isAuditor = roles.some((role) => role.role_id === ROLE_IDS[5]);

  const roleSidebarRoutes = () => {
    if (isEnterpriseAdmin && !settings.isViewAs) {
      return enterpriseSidebarRoutes(organization?.name ?? "Loading...");
    }

    if (isAuditor) {
      if (settings.isViewAs) return auditorViewAsSidebarRoutes;

      return auditorSidebarRoutes;
    }

    return sidebarRoutes;
  };

  const isIncidentManagementPlan = React.useMemo(() => {
    if (!subscriptionPlans) return false;
    const incidentManagementPlan = subscriptionPlans.items.find(
      (plan) => plan.product.name === STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME
    );

    return Boolean(incidentManagementPlan);
  }, [subscriptionPlans]);

  const hasIncidentManagementAddon = React.useMemo(() => {
    if (!subscriptionPlans) return false;
    const imAddon = subscriptionPlans.items.find(
      (plan) =>
        plan.product.name === "Incident Management Add-On" && plan.quantity > 0
    );

    const imAddonIncludedPlan = subscriptionPlans.items.find(
      (plan) =>
        plan.product.name === STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME ||
        plan.product.name === "VPO"
    );

    return Boolean(imAddon) || Boolean(imAddonIncludedPlan);
  }, [subscriptionPlans]);

  const disabledKeys = React.useMemo(() => {
    if (!subscriptionPlans)
      return [
        MenuId.training,
        MenuId.policy_manager,
        MenuId.incident_management,
      ];

    const keys = [] as MenuId[];

    const trainingSeats =
      (subscriptionPlans.items.find(
        (plan) => plan.product.name === "Training Seat"
      )?.quantity ?? 0) +
      (subscriptionPlans.items[0].db_product?.training_seats ?? 0);

    if (trainingSeats === 0) {
      keys.push(MenuId.training, MenuId.policy_manager);
    }

    if (!hasIncidentManagementAddon && !isIncidentManagementPlan) {
      keys.push(MenuId.incident_management);
    }

    return keys;
  }, [subscriptionPlans, hasIncidentManagementAddon, isIncidentManagementPlan]);

  const noIMOnProductionMenus = React.useMemo(() => {
    let modifiedMenus = menus;

    if (!hasIncidentManagementAddon && !isIncidentManagementPlan) {
      modifiedMenus = modifiedMenus.filter(
        (menu_id) => menu_id !== "incident_management"
      );
    }
    if (isIncidentManagementPlan) {
      modifiedMenus = modifiedMenus.filter(
        (menu_id) => menu_id === "incident_management"
      );
    }

    if (process.env.NEXT_PUBLIC_INCIDENT_TESTING_MODE !== "true") {
      modifiedMenus = modifiedMenus.filter(
        (menu_id) => menu_id !== "incident_management"
      );
    }

    if (isPhiAdmin && user?.organization_id === organizationId) {
      modifiedMenus = modifiedMenus.filter(
        (menu_id) => menu_id !== "incident_management"
      );
    }

    return modifiedMenus;
  }, [
    menus,
    isPhiAdmin,
    user,
    organizationId,
    hasIncidentManagementAddon,
    isIncidentManagementPlan,
  ]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - 60px)`,
        }}
      >
        <Scrollbar options={{ suppressScrollX: true }}>
          <Box
            sx={{
              justifyContent: "center",
              p: 2,
              display: { lg: "none", xs: "flex" },
            }}
          >
            <Image
              alt="PHI Logo"
              src={
                theme.palette.mode === "light"
                  ? "/logos/phi-logo-dark.svg"
                  : "/logos/phi-logo.svg"
              }
              height={42}
              width={230}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <ViewingCard />
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <NavCollection
              visibleKeys={noIMOnProductionMenus}
              sidebarRoutes={roleSidebarRoutes()}
              pathname={router.pathname}
              isViewingAs={user?.organization_id !== organizationId}
              disabledKeys={disabledKeys}
            />
          </Box>
        </Scrollbar>
      </Box>
      {seeOrgSettings && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 15,
          }}
        >
          <NavItem
            key={sidebarRouteOrganized.key}
            active={isActive(router.pathname, sidebarRouteOrganized.path)}
            icon={sidebarRouteOrganized.icon}
            path={sidebarRouteOrganized.path}
            title={sidebarRouteOrganized.title}
          />
        </div>
      )}
      {isPhiAdmin &&
        settings.isViewAs !== undefined &&
        settings.isViewAs !== user?.organization_id && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 15,
              width: "90%",
            }}
          >
            <NavItem
              key={sidebarRouteOrganized.key}
              active={isActive(router.pathname, sidebarRouteOrganized.path)}
              icon={sidebarRouteOrganized.icon}
              path={sidebarRouteOrganized.path}
              title={sidebarRouteOrganized.title}
            />
          </div>
        )}
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 280,
          },
        }}
        sx={{ display: { lg: "none", xs: "block" } }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        sx={{ display: { lg: "block", xs: "none" } }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};

export default DashboardSidebar;
