export const SOC2_ADD_ON_NAMES = [
  {
    name: "SOC2 Type I pre-audit",
    addon_id: "8af5247e-1a2a-4438-b056-11640136c61f",
    description: "",
  },
  {
    name: "SOC2 Type II pre-audit",
    addon_id: "6967e96b-dd0c-4c75-b7a8-0dbaca11f1c4",
    description: "",
  },
  {
    name: "SOC2 Assessment",
    addon_id: "e81e6370-b61a-4187-8789-58e9f5f7ad7f",
    description: "",
  },
];
