import * as React from "react";
import { BareFetcher } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

const DEFAULT_FETCH_LIMIT = 1000;

interface UseSWRPaginateParams<TData, TError>
  extends SWRInfiniteConfiguration<TData[], TError, BareFetcher<TData[]>> {
  limit?: number;
  url?: string;
  requestParams?: string;
}

export const useSWRPaginate = <TData, TError = unknown>({
  limit = DEFAULT_FETCH_LIMIT,
  url,
  requestParams = "",
  ...config
}: UseSWRPaginateParams<TData, TError>) => {
  const [data, setData] = React.useState<TData[] | null>(null);

  const extraParams = React.useMemo(
    () => (requestParams ? `&${requestParams}` : ""),
    [requestParams]
  );

  const queryStringExists = url ? url.includes("?") : false;

  const getKey = (pageIndex: number) =>
    url
      ? `${url}${
          queryStringExists ? "&" : "?"
        }page=${pageIndex}&limit=${limit}${extraParams}`
      : null;

  const {
    data: pages,
    error,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite<TData[], TError>(getKey, {
    ...config,
    revalidateFirstPage: false,
  });

  React.useEffect(() => {
    if (!pages || isValidating) {
      return;
    }

    if (pages[pages.length - 1].length === limit) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setSize(size + 1);
    } else {
      setData(pages.flatMap((d) => d));
    }
  }, [pages, isValidating, setSize, size, limit]);

  return { error, data, isValidating, mutate };
};
