import { PendingPayment, Plan } from "@prisma/client";

import { ORGANIZATION_LIMIT } from "constants/pagination-limits";
import { useSWRPaginate } from "hooks/useSWRPaginate";

import { ApiError, Organization } from "types/api";

export const useOrganizationsWithCoachUsers = (params: {
  coachId?: string;
  auditorId?: string;
}) => {
  let url = "organization/coach/";

  if (params.auditorId) {
    url += `auditor/${params.auditorId}`;
  } else if (params.coachId) {
    url += params.coachId;
  }

  const { data, error, mutate } = useSWRPaginate<
    Organization & { pending_payment?: (PendingPayment & { plan: Plan })[] },
    ApiError
  >({
    url,
    limit: ORGANIZATION_LIMIT,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    organizations: data,
    organizationsError: error,
    isOrganizationsLoading: !data && !error,
    mutateOrganizations: mutate,
  };
};
