import { createSvgIcon } from "@mui/material";

const SpeakerPhone = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2.99947C17.9999 2.82908 17.9563 2.66153 17.8733 2.51274C17.7902 2.36394 17.6706 2.23883 17.5256 2.14927C17.3806 2.05971 17.2152 2.00868 17.045 2.00101C16.8748 1.99335 16.7054 2.0293 16.553 2.10547L8.763 5.99947H5C4.20435 5.99947 3.44129 6.31554 2.87868 6.87815C2.31607 7.44076 2 8.20382 2 8.99947C2 9.79512 2.31607 10.5582 2.87868 11.1208C3.44129 11.6834 4.20435 11.9995 5 11.9995H5.28L7.051 17.3155C7.11735 17.5147 7.24472 17.6879 7.41505 17.8107C7.58538 17.9335 7.79004 17.9995 8 17.9995H9C9.26522 17.9995 9.51957 17.8941 9.70711 17.7066C9.89464 17.519 10 17.2647 10 16.9995V12.6175L16.553 15.8935C16.7054 15.9696 16.8748 16.0056 17.045 15.9979C17.2152 15.9903 17.3806 15.9392 17.5256 15.8497C17.6706 15.7601 17.7902 15.635 17.8733 15.4862C17.9563 15.3374 17.9999 15.1699 18 14.9995V2.99947Z"
      fillOpacity="0.86"
    />
  </svg>,
  "SpeakerPhone"
);

export default SpeakerPhone;
