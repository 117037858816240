import useSWR from "swr";

import { ApiError, Plan } from "types/api";

export const usePlan = (planId?: string[] | string | null) => {
  const { data, error, mutate } = useSWR<Plan, ApiError>(
    typeof planId === "string" ? `payment/plan/${planId}` : null
  );

  return {
    plan: data,
    planError: error,
    isPlanLoading: !data && !error,
    mutatePlan: mutate,
  };
};
