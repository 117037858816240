import type {
  ControlTask as ControlTaskType,
  ControlTaskTemplate,
  Document,
  EvidenceType,
  Legislation,
  ControlActivity,
  AssessmentTypes,
  RiskLevel,
  Assessment,
} from "@prisma/client";
import {
  ControlTaskPriority,
  ControlTaskRiskTreatmentStrategy,
  ControlTaskStatus,
} from "@prisma/client";

import type { ApiPostSuccess } from "./base";

export type CustomControlTaskCreateParams = Pick<
  ControlTaskType,
  | "assessment_id"
  | "impact"
  | "likelihood"
  | "priority"
  | "risk_treatment_strategy"
  | "status"
> & {
  control_name: string;
  assessment_id: string;
  assessment_name?: string;
  assessment_type?: AssessmentTypes;
};

export type CustomControlTaskCreateUserParams =
  CustomControlTaskCreateParams & {
    organization_id: string;
    created_by: string;
  };

export type ControlTaskCreateResponse = ApiPostSuccess<{
  control_task_id: string;
}>;

export type ControlTaskUpdateParams = Partial<
  Omit<
    ControlTaskType,
    | "control_task_id"
    | "control_task_template_id"
    | "created_at"
    | "custom_description"
    | "custom_name"
    | "due_date"
    | "start_date"
    | "updated_at"
  > & {
    start_date?: Date | string;
    due_date?: Date | string;
  }
>;

export type ControlTaskUpdateAsAuditorParams = Partial<
  Pick<ControlTaskType, "recommendation">
>;

export type ControlTaskUpdateInitialValues = Partial<
  Omit<
    ControlTaskType,
    | "control_task_id"
    | "control_task_template_id"
    | "created_at"
    | "due_date"
    | "impact"
    | "likelihood"
    | "priority"
    | "risk_treatment_strategy"
    | "start_date"
    | "status"
    | "updated_at"
  > & {
    start_date?: Date | string;
    due_date?: Date | string;
    priority?: ControlTaskPriority | string;
    risk_treatment_strategy?: ControlTaskRiskTreatmentStrategy | string;
    status?: ControlTaskStatus | string;
    likelihood?: RiskLevel | string;
    impact?: RiskLevel | string;
    action_plan_select?: string;
    person_responsible_select?: string;
  }
>;

export type ControlTaskWithSelectUpdateParams = Omit<
  ControlTaskUpdateParams,
  "custom_description" | "custom_name"
> & {
  action_plan_select?: string;
  person_responsible_select?: string;
};

export type ControlTaskUpdateResponse = ApiPostSuccess<{
  control_task_id: string;
}>;

export type ControlTaskDeleteResponse = ApiPostSuccess<{
  control_task_id: string;
}>;

export interface ControlTask extends ControlTaskType {
  control_task_template?: ControlTaskTemplate & {
    legislations?: Legislation[];
    allowed_evidence?: EvidenceType[];
    related_to?: (ControlTaskTemplate & {
      control_task: ControlTask;
      assessment?: Assessment;
    })[];
    related_by?: (ControlTaskTemplate & {
      control_task: ControlTask;
      assessment?: Assessment;
    })[];
  };
  documents?: Document[];
  assessment?: Assessment;
}

export {
  ControlTaskPriority,
  ControlTaskRiskTreatmentStrategy,
  ControlTaskStatus,
};

export interface ControlActivityJoin extends ControlActivity {
  control_task: ControlTask & {
    control_task_template?: ControlTaskTemplate | null;
  };
  is_control?: boolean;
}
