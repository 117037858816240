import { SOC2_ADD_ON_NAMES } from "../../../seeds/data/soc2_addons";

export type AddOnNameType =
  | "coachingHours"
  | "imAddOn"
  | "nonRecurringCoaching"
  | "phiShield"
  | "phiShieldPlus"
  | "soc2Assessment"
  | "soc2Type1"
  | "soc2Type2"
  | "trainingSeats";

export type AddOnStripePriceNameType =
  | "coachingHoursStripePrice"
  | "imAddOnStripePrice"
  | "nonRecurringCoachingStripePrice"
  | "phiShieldPlusStripePrice"
  | "phiShieldStripePrice"
  | "soc2AssessmentStripePrice"
  | "soc2Type1StripePrice"
  | "soc2Type2StripePrice"
  | "trainingSeatsStripePrice";

export const ADDON_IDS: Record<AddOnNameType, string> = {
  coachingHours: "7b4840db-7e40-41a5-a058-2e1acc319514",
  trainingSeats: "70b14daf-521e-4bdd-a447-3e4b9b06e0a0",
  phiShield: "76e83c3b-905d-445b-8dac-21156d32f51c",
  phiShieldPlus: "b0d523ea-e057-4439-ba15-0656b1463ad0",
  imAddOn: "177f1e5a-97c9-476c-a5e8-cc7399517a19",
  nonRecurringCoaching: "54a76206-6f27-4bb0-b57b-cbbeb1339648",
  soc2Type1: SOC2_ADD_ON_NAMES[0].addon_id,
  soc2Type2: SOC2_ADD_ON_NAMES[1].addon_id,
  soc2Assessment: SOC2_ADD_ON_NAMES[2].addon_id,
};
