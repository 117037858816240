import { Box } from "@mui/material";
import { forwardRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import type { ScrollBarProps as PerfectScrollbarProps } from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

type ScrollbarProps = PerfectScrollbarProps;

const Scrollbar = forwardRef<HTMLElement, ScrollbarProps>((props, ref) => {
  const { children, ...other } = props;

  // NextJS does not like this too much
  const isMobile = false;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: "auto" }}>
        {children}
      </Box>
    );
  }

  return (
    <PerfectScrollbar
      // @ts-expect-error
      ref={ref}
      {...other}
    >
      {children}
    </PerfectScrollbar>
  );
});

export default Scrollbar;
