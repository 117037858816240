import { createSvgIcon } from "@mui/material";

const Key = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.00001C16.0003 6.93719 15.781 7.86139 15.3598 8.69856C14.9386 9.53573 14.3271 10.2626 13.5744 10.8209C12.8216 11.3792 11.9486 11.7534 11.0252 11.9135C10.1018 12.0737 9.15376 12.0153 8.257 11.743L8 12L7 13L6 14H4V16H0V12L4.257 7.74301C4.00745 6.91803 3.93857 6.04896 4.05504 5.19496C4.17152 4.34096 4.47062 3.52208 4.93199 2.79406C5.39336 2.06604 6.00616 1.44596 6.72869 0.976034C7.45122 0.506108 8.26652 0.197365 9.11908 0.0708197C9.97164 -0.0557254 10.8415 0.00289777 11.6693 0.242699C12.4972 0.482501 13.2637 0.897851 13.9166 1.46048C14.5696 2.02311 15.0936 2.71981 15.4531 3.50315C15.8127 4.2865 15.9992 5.13811 16 6.00001ZM10 2.00001C9.73478 2.00001 9.48043 2.10537 9.29289 2.29291C9.10536 2.48044 9 2.7348 9 3.00001C9 3.26523 9.10536 3.51958 9.29289 3.70712C9.48043 3.89466 9.73478 4.00001 10 4.00001C10.5304 4.00001 11.0391 4.21073 11.4142 4.5858C11.7893 4.96087 12 5.46958 12 6.00001C12 6.26523 12.1054 6.51959 12.2929 6.70712C12.4804 6.89466 12.7348 7.00001 13 7.00001C13.2652 7.00001 13.5196 6.89466 13.7071 6.70712C13.8946 6.51959 14 6.26523 14 6.00001C14 4.93915 13.5786 3.92173 12.8284 3.17159C12.0783 2.42144 11.0609 2.00001 10 2.00001Z"
    />
  </svg>,
  "Key"
);

export default Key;
