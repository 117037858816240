import useSWR from "swr";

import { ApiError, ResourceWithAuthor } from "types/api";

export const useResourcesWithAuthors = () => {
  const { data, error, mutate } = useSWR<ResourceWithAuthor[], ApiError>(
    "resource/with-author"
  );

  return {
    resources: data,
    resourcesError: error,
    isresourcesLoading: !data && !error,
    mutateResources: mutate,
  };
};
