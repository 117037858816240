import { createSvgIcon } from "@mui/material";

const DocumentSearch = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H10.586C11.1164 2.00011 11.625 2.2109 12 2.586L15.414 6C15.7891 6.37499 15.9999 6.88361 16 7.414V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H12.472C13.4938 16.8569 14.0393 15.366 13.9964 13.8334C13.9535 12.3008 13.3255 10.8428 12.2414 9.75864C11.1572 8.6745 9.69917 8.04652 8.16657 8.00363C6.63396 7.96074 5.14308 8.50619 4 9.528V4Z"
      fillOpacity="0.86"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0002 10C7.29487 9.9999 6.60204 10.1863 5.99199 10.5403C5.38193 10.8943 4.87633 11.4034 4.52645 12.0158C4.17657 12.6283 3.99485 13.3223 3.99973 14.0276C4.0046 14.733 4.19589 15.4245 4.5542 16.032L3.2932 17.292C3.20029 17.3848 3.12658 17.4951 3.07627 17.6164C3.02596 17.7377 3.00005 17.8678 3 17.9991C2.99995 18.1305 3.02578 18.2606 3.076 18.3819C3.12622 18.5033 3.19986 18.6136 3.2927 18.7065C3.38555 18.7994 3.49578 18.8731 3.61712 18.9234C3.73845 18.9737 3.8685 18.9997 3.99985 18.9997C4.1312 18.9997 4.26127 18.9739 4.38264 18.9237C4.50401 18.8735 4.61429 18.7998 4.7072 18.707L5.9682 17.446C6.49805 17.7583 7.09277 17.9442 7.70614 17.9892C8.31951 18.0343 8.93502 17.9373 9.50482 17.7058C10.0746 17.4743 10.5834 17.1146 10.9915 16.6545C11.3996 16.1944 11.6962 15.6464 11.8581 15.0531C12.02 14.4598 12.043 13.8371 11.9251 13.2335C11.8072 12.6298 11.5518 12.0615 11.1785 11.5727C10.8053 11.0838 10.3244 10.6876 9.77317 10.4149C9.22194 10.1421 8.61523 10.0001 8.0002 10ZM6.0002 14C6.0002 13.4696 6.21092 12.9609 6.58599 12.5858C6.96106 12.2107 7.46977 12 8.0002 12C8.53064 12 9.03934 12.2107 9.41442 12.5858C9.78949 12.9609 10.0002 13.4696 10.0002 14C10.0002 14.5304 9.78949 15.0391 9.41442 15.4142C9.03934 15.7893 8.53064 16 8.0002 16C7.46977 16 6.96106 15.7893 6.58599 15.4142C6.21092 15.0391 6.0002 14.5304 6.0002 14Z"
      fillOpacity="0.86"
    />
  </svg>,
  "DocumentSearch"
);

export default DocumentSearch;
