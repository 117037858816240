import {
  Plan as PrismaPlan,
  AddOn,
  PendingPayment,
  ReceiptType,
} from "@prisma/client";
import type { Stripe } from "stripe";

import { ApiPostSuccess } from "./base";

export const STRIPE_INCIDENT_MANAGEMENT_PLAN_NAME = "Incident Management Plan";

export type StripeCustomerCreateParams = {
  organization_id: string;
  email: string;
  name: string;
  token: string;
};

export type StripeCustomerUpdateParams = {
  name: string;
  token: string;
};

export type StripeSubscriptionCreateParams = {
  organization_id: string;
  plan_id: string;
  coaching_hours: string;
  training_seats: string;
  tax_rate_id?: string;
  phi_shield_level?: string;
  phi_shield_seats?: string;
  promo_code_id?: string;
  currency?: string;
  im_addon?: boolean;
};

export type StripeSubscriptionUpdateParams = {
  organization_id: string;
  plan_id: string;
  new_plan_id?: string;
  new_coaching_hours?: string;
  new_training_seats?: string;
  phi_shield_level?: string;
  new_phi_shield_level?: string;
  new_phi_shield_seats?: string;
  tax_rate_id?: string;
  promo_code_id?: string;
  currency?: string;
  pending_payment_id?: string;
  im_addon?: boolean;
};

export type StripeCustomerCreateResponse =
  | ApiPostSuccess<{
      stripe_customer_id: string | null;
    }>
  | { raw: Error };

export type StripeCustomerUpdateResponse =
  | ApiPostSuccess<{
      stripe_customer_id: string | null;
    }>
  | { raw: Error };

export type StripeSubscriptionCreateResponse = ApiPostSuccess<{
  subscription_id: string;
  subscription: SubscriptionPlanInfo;
}>;

export type StripeSubscriptionUpdateResponse = ApiPostSuccess<{
  subscription_id: string;
  subscription: Stripe.Subscription;
}>;

export interface SubscriptionPlanInfoItem {
  price: Stripe.Price;
  product: Stripe.Product;
  db_product: PrismaPlan | null;
  quantity: number;
  lookup_key: string | null;
  non_recurring?: boolean;
  is_recurring?: boolean;
  is_SOC2_addOn?: boolean;
}

export interface SubscriptionPlanInfo {
  items: SubscriptionPlanInfoItem[];
  scheduled_items?: SubscriptionPlanInfoItem[];
  start_date?: number;
  current_period_start?: number;
  current_period_end?: number;
  discount: Stripe.Discount | number | null;
  pay_on_account?: boolean;
  coupon?: Stripe.Coupon | null;
}

export type StripeInterval = Stripe.Plan.Interval;

export type StripeBillingInfo = {
  contact: string;
  email: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  postal_code: string;
  last_charge: number | null | undefined;
  last4: string;
};

export type StripeBillingInfoUpdateParams = Pick<
  StripeBillingInfo,
  "contact" | "email"
>;

export type StripeBillingInfoUpdateResponse =
  | ApiPostSuccess<Pick<StripeBillingInfo, "contact" | "email">>
  | { raw: Error };

export interface TaxRateParams {
  id: string;
  country?: string | null;
  jurisdiction?: string | null;
  percentage?: number | null;
  tax_type?: string | null;
}

export type InvoiceDetails = {
  id: string;
  created: number;
  subscription_start?: number;
  subscription_end?: number;
  payment_method: string;
  total: number;
  status: boolean;
  paid: string;
  url: string | null | undefined;
};

export interface Plan extends PrismaPlan {
  prices: Stripe.Price[];
}

export interface PlanData {
  id: string;
  name: string;
  add_on: string;
  soc2_add_ons: string;
  renewal_date?: number;
  price: number;
  training_seats: number;
  coaching_hours: number;
  currency: string;
  incident_management: string;
}
export type { AddOn };

export type StripePromoCodeFetchParams = {
  promoCode: string;
};

export type StripePromoCodeFetchResponse = Stripe.PromotionCode | null;

export type PendingPaymentUpdateParams = Partial<
  Omit<
    PendingPayment,
    | "created_at"
    | "organization_id"
    | "pay_on_account"
    | "pending_payment_id"
    | "renewal_date"
    | "scheduled_date"
    | "updated_at"
  >
> & {
  due_now?: number;
  receipt_type?: ReceiptType;
  generate_receipt?: boolean;
};

export type RenewalDateUpdateParams = Pick<PendingPayment, "renewal_date">;

export type PendingPaymentAdditionUpdateParams = Partial<
  Pick<
    PendingPayment,
    | "non_recurring_hours"
    | "soc2_assessment_expiry"
    | "soc2_type1_expiry"
    | "soc2_type2_expiry"
    | "training_seats"
  >
> & {
  due_now: number;
  receipt_type?: ReceiptType;
  skipCreateScheduledPayment?: boolean;
};

export type PendingPaymentCreateParams = Partial<
  Omit<
    PendingPayment,
    | "admin_plan_upgrade"
    | "created_at"
    | "generate_reciept"
    | "pay_on_account"
    | "pending_payment_id"
    | "receipt_type"
    | "renewal_date"
    | "scheduled_date"
    | "updated_at"
  >
>;

export type PendingPaymentResponse = ApiPostSuccess<
  Pick<PendingPayment, "pending_payment_id">
>;

export type PurchaseStripeProductParams = {
  organization_id: string;
  addon_id: string;
  non_recurring_hours?: string;
  tax_rate_id?: string;
  promo_code_id?: string;
  currency?: string;
  delete_pending_payment?: boolean;
};

export type StripePurchaseResponse = ApiPostSuccess<{
  addon_id: string;
  payment_intent_id: string;
}>;

export type StripeCoupon = Stripe.Coupon;

export type PayOnAccountUpdateSubscriptionItem = {
  plan_id?: string;
  coaching_hours?: number;
  training_seats?: number;
  phi_shield_level?: string;
  phi_shield_seats?: number;
  soc2_addon_id?: string | null;
  downgrade?: boolean;
};

export type PendingPaymentWithPlan = PendingPayment & { plan?: PrismaPlan };

export type SubscriptionCycleItem = {
  coaching_hours: number;
  training_seats: number;
  phi_shield_level: string;
  phi_shield_seats: number;
  created_at: number;
  period_start_date: number;
  period_end_date: number;
};

export type SubscriptionCycles = {
  cycles: SubscriptionCycleItem[];
};

export type SOC2AddOns = SOC2AddOnId[];

export type SOC2AddOnId =
  | "8af5247e-1a2a-4438-b056-11640136c61f"
  | "6967e96b-dd0c-4c75-b7a8-0dbaca11f1c4"
  | "e81e6370-b61a-4187-8789-58e9f5f7ad7f";
