import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";
import * as React from "react";

import { ROLE_IDS } from "../../../seeds/data/role";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import { useOrganization } from "requests/queries";

export const ViewingCard: React.FC = () => {
  const { user, organizationId, switchOrganization, roles } = useAuth();
  const router = useRouter();
  const { settings, saveSettings } = useSettings();

  const isOrgMember = user?.organization_id === organizationId;
  const { organization: theOrganization } = useOrganization(organizationId);

  const { organization } = useOrganization(
    typeof organizationId === "string" ? organizationId : null
  );

  const isEnterpriseAdmin = roles.some((r) => r.role_id === ROLE_IDS[4]);

  const clearOrganization = () => {
    switchOrganization(user?.organization_id ?? null);
    saveSettings({ ...settings, isViewAs: undefined });
    if (
      roles.some(
        (role) => role.role_id === ROLE_IDS[0] || role.role_id === ROLE_IDS[5]
      )
    ) {
      router.push(ROUTES.CLIENTS);
    } else if (roles.some((role) => role.role_id === ROLE_IDS[1])) {
      router.push(ROUTES.COACH_DASHBOARD);
    } else {
      router.push(ROUTES.ORGANIZATIONS);
    }
  };

  return (
    <>
      {(!isOrgMember || settings.isViewAs) && (
        <Button
          startIcon={<ArrowBack />}
          variant="text"
          onClick={clearOrganization}
          fullWidth
        >
          {" "}
          {isEnterpriseAdmin
            ? "Back to All Organizations"
            : "Back to All Clients"}
        </Button>
      )}
      <Box
        sx={{
          mt: 2,
          alignItems: "center",
          backgroundColor:
            isOrgMember && !settings.isViewAs
              ? "background.default"
              : "rgba(245, 137, 73, 0.08)",
          borderRadius: 1,
          display: "flex",
          overflow: "hidden",
          border: isOrgMember && !settings.isViewAs ? "none" : "1px solid",
          borderColor: "secondary.main",
          p: 2,
        }}
      >
        {isOrgMember || (settings.isViewAs && user) ? (
          <Box sx={{ ml: 2 }}>
            <Typography color="textPrimary" variant="subtitle1">
              {theOrganization?.name ?? "Loading..."}
            </Typography>
            <Typography color="textPrimary" variant="subtitle2">
              {user.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {user.email}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ ml: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              Viewing Client:
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {organization?.name ?? "Loading..."}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
