import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import type { FC } from "react";
import * as React from "react";

import { SkeletonLoader } from "./SkeletonLoader";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import { usePendingPaymentForOrg } from "requests/queries";
import { useCoupons } from "requests/queries/useCoupons";

const RegisterSplashScreen: FC<{ logout: () => Promise<void> }> = ({
  logout,
}) => {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId } = useAuth();
  const { coupons } = useCoupons();

  const { pendingPayment, isPendingPaymentLoading } =
    usePendingPaymentForOrg(organizationId);

  const soc2_addons = React.useMemo(() => {
    if (!pendingPayment) return {};

    return {
      soc2_type1: pendingPayment.soc2_type1_expiry ? true : undefined,
      soc2_type2: pendingPayment.soc2_type2_expiry ? true : undefined,
      soc2_assessment: pendingPayment.soc2_assessment_expiry ? true : undefined,
    };
  }, [pendingPayment]);

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      router.push(ROUTES.AUTH_LOGIN);
    } catch (err: unknown) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "primary.main",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      }}
    >
      <Card>
        <CardHeader title="Access Error" />
        <Divider />
        <CardContent>
          <SkeletonLoader loading={isPendingPaymentLoading}>
            <Box sx={{ mt: 2 }}>
              <Typography color="textSecondary" variant="subtitle2">
                <div style={{ padding: 12 }}>
                  You must be subscribed to see this route.
                </div>
              </Typography>
              {pendingPayment ? (
                <Link
                  href={{
                    pathname: `${ROUTES.PAYMENTS}/${pendingPayment.plan_id}`,
                    query: {
                      coaching_hours: pendingPayment.coaching_hours,
                      training_seats: pendingPayment.training_seats,
                      phi_shield_level: pendingPayment.phi_shield_level,
                      phi_shield_seats: pendingPayment.phi_shield_level
                        ? pendingPayment.phi_shield_seats
                        : "",
                      coupon_name:
                        coupons?.find(
                          (coupon) => coupon.id === pendingPayment.coupon
                        )?.name ?? "",
                      im_addon: pendingPayment.im_addon,
                      ...soc2_addons,
                    },
                  }}
                  passHref
                >
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    Go To Payments
                  </Button>
                </Link>
              ) : (
                <Link href={ROUTES.PLAN_SELECTION} passHref>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    Choose Plan
                  </Button>
                </Link>
              )}
              <Box paddingTop="16px">
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleLogout}
                >
                  Log Out
                </Button>
              </Box>
            </Box>
          </SkeletonLoader>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegisterSplashScreen;
