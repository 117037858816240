import { Box, Button, ListItem } from "@mui/material";
import type { ListItemProps } from "@mui/material";
import Link from "next/link";
import * as React from "react";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  icon?: React.ReactNode;
  path?: string;
  title: string;
  disabled?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
  active = false,
  icon,
  path,
  title,
  disabled = false,
}) => (
  <ListItem
    disableGutters
    sx={{
      display: "flex",
      py: 0,
    }}
  >
    <Link href={path ?? ""} passHref>
      <Button
        startIcon={icon}
        fullWidth
        disabled={disabled}
        sx={{
          fontSize: "0.8rem",
          color: "text.secondary",
          justifyContent: "flex-start",
          pl: 2,
          pr: 1,
          py: 1.5,
          mb: 1,
          textAlign: "left",
          textTransform: "none",
          borderRadius: 4,
          "& svg": {
            color: "secondary.main",
          },
          ...(active && {
            color: "text.primary",
            fontWeight: "fontWeightBold",
            backgroundColor: "background.selected",
          }),
        }}
        variant="text"
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
      </Button>
    </Link>
  </ListItem>
);
