import { Logout, ManageAccounts } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Popover,
  Typography,
  ListItemButton,
} from "@mui/material";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import type { FC } from "react";

import { ThemeSwitcher } from "components/ThemeSwitcher";
import { UploadedAvatar } from "components/UploadedAvatar";

import { ROUTES } from "constants/routes";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import { useUser } from "requests/queries";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const router = useRouter();
  const { user: authUser, logout } = useAuth();
  const { user } = useUser(authUser?.user_id);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const { settings, saveSettings } = useSettings();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      saveSettings({ ...settings, isViewAs: undefined });
      await logout();
      router.push(ROUTES.AUTH_LOGIN);
    } catch (err: unknown) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <UploadedAvatar
          sx={{
            height: 32,
            width: 32,
          }}
          image={user?.avatar}
        />
        <Box sx={{ pl: 2, display: { xs: "none", sm: "block" } }}>
          {user?.email}
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
      >
        <List dense>
          <ListItem>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption">{user?.name}</Typography>
              <ThemeSwitcher />
            </Box>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                router.push(ROUTES.PROFILE_USER);
              }}
            >
              <ListItemIcon>
                <ManageAccounts />
              </ListItemIcon>
              <ListItemText primary="User Profile" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default AccountPopover;
