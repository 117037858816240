import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, Document } from "types/api";

export const useDocument = (documentId?: string | null) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<Document, ApiError>(
    organizationId && typeof documentId === "string"
      ? `organization/${organizationId}/document/${documentId}`
      : null
  );

  return {
    document: data,
    documentError: error,
    isDocumentLoading: !data && !error,
    mutateDocument: mutate,
  };
};
