import useSWR from "swr";

import { ApiError, AssessmentTemplateControls } from "types/api";

export const useAssessmentTemplates = (
  assessmentTemplateId?: string[] | string | null
) => {
  const { data, error, mutate } = useSWR<
    AssessmentTemplateControls | AssessmentTemplateControls[],
    ApiError
  >(
    typeof assessmentTemplateId === "string"
      ? `assessment-template/${assessmentTemplateId}`
      : "assessment-template"
  );

  return {
    assessmentTemplates: data,
    assessmentError: error,
    isAssessmentTemplateLoading: !data && !error,
    mutateAssessmentTemplate: mutate,
  };
};
