import useSWR from "swr";

import { ApiError, PolicyTemplate } from "types/api";

export const usePolicyTemplates = () => {
  const { data, error, mutate } = useSWR<PolicyTemplate[], ApiError>("policy");

  return {
    policyTemplates: data,
    policyTemplatesError: error,
    isPolicyTemplatesLoading: !data && !error,
    mutatePolicyTemplates: mutate,
  };
};
