import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, User } from "types/api";

export const useUser = (userId?: string | null) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<User, ApiError>(
    userId && organizationId
      ? `organization/${organizationId}/user/${userId}`
      : null
  );

  return {
    user: data,
    userError: error,
    isUserLoading: !data && !error,
    mutateUser: mutate,
  };
};
