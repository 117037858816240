import useSWR from "swr";

import { ApiError, StripeCoupon } from "types/api";

export const useCoupons = () => {
  const { data, error } = useSWR<StripeCoupon[], ApiError>(`payment/coupon`);

  return {
    coupons: data,
    couponsError: error,
    isCouponsLoading: !data && !error,
  };
};
