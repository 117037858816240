import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, ControlTask } from "types/api";

export const useAssessmentControl = (
  assessmentId?: string[] | string | null,
  controlId?: string[] | string | null
) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<ControlTask, ApiError>(
    typeof assessmentId === "string" &&
      typeof controlId === "string" &&
      organizationId
      ? `organization/${organizationId}/assessment/${assessmentId}/control/${controlId}`
      : null
  );

  return {
    control: data,
    controlError: error,
    isControlLoading: !data && !error,
    mutateControl: mutate,
  };
};
