import { Avatar, AvatarProps } from "@mui/material";
import * as React from "react";

export const UploadedAvatar: React.FC<
  AvatarProps & {
    image?: Buffer | null;
  }
> = ({ image, ...props }) => {
  if (!image) return <Avatar {...props} />;

  const base64 = Buffer.from(image).toString("base64");
  const src = `data:image/png;base64,${base64}`;

  return <Avatar {...props} src={src} />;
};
