import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, ControlTask } from "types/api";

export const useAssessmentControls = (assessmentId?: string) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<ControlTask[], ApiError>(
    assessmentId && organizationId
      ? `organization/${organizationId}/assessment/${assessmentId}/control`
      : null
  );

  return {
    controls: data,
    controlsError: error,
    isControlsLoading: !data && !error,
    mutateControls: mutate,
  };
};
