import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, Assessment } from "types/api";

export const useAssessment = (assessmentId?: string[] | string | null) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<Assessment, ApiError>(
    typeof assessmentId === "string" && organizationId
      ? `organization/${organizationId}/assessment/${assessmentId}`
      : null
  );

  return {
    assessment: data,
    assessmentError: error,
    isAssessmentLoading: !data && !error,
    mutateAssessment: mutate,
  };
};
