import Stripe from "stripe";
import useSWR from "swr";

import { AddOn, ApiError } from "types/api";

interface AddonInfo {
  prices: Stripe.Price[];
  addOns: AddOn[];
}

export const useAddons = (currency = "CAD") => {
  const { data, error, mutate } = useSWR<AddonInfo, ApiError>(
    `payment/price/addons?currency=${currency}`
  );

  return {
    prices: data?.prices,
    addons: data?.addOns,
    addonsError: error,
    isAddonsLoading: !data && !error,
    mutateAddons: mutate,
  };
};
