import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import * as React from "react";

import { NavItem } from "./NavItem";

import { MenuId } from "types/api";

export interface Item {
  path: string;
  icon?: React.ReactNode;
  key: MenuId;
  title: string;
  showViewingAs?: boolean;
}

export interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
  disabledKeys?: MenuId[];
}

const isActive = (pathname: string, itemPath?: string) => {
  if (!itemPath) {
    return false;
  }

  return `${pathname}/`.startsWith(`${itemPath}/`);
};

const NavSection: React.FC<NavSectionProps> = ({
  items,
  pathname,
  title,
  disabledKeys,
}) => (
  <List
    subheader={
      <ListSubheader
        disableGutters
        disableSticky
        sx={{
          color: "text.primary",
          fontSize: "0.75rem",
          lineHeight: 2.5,
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {title}
      </ListSubheader>
    }
  >
    <List disablePadding>
      {items.map((item) => (
        <NavItem
          key={item.key}
          active={isActive(pathname, item.path)}
          icon={item.icon}
          path={item.path}
          title={item.title}
          disabled={disabledKeys?.includes(item.key)}
        />
      ))}
    </List>
  </List>
);

interface NavCollectionProps {
  sidebarRoutes: Pick<NavSectionProps, "items" | "title">[];
  visibleKeys: MenuId[];
  pathname: string;
  isViewingAs: boolean;
  disabledKeys?: MenuId[];
}

export const NavCollection: React.FC<NavCollectionProps> = ({
  sidebarRoutes,
  visibleKeys,
  pathname,
  isViewingAs,
  disabledKeys,
}) => (
  <>
    {sidebarRoutes.map((section) => {
      const items = section.items.filter((item) =>
        isViewingAs ? item.showViewingAs : visibleKeys.includes(item.key)
      );

      if (items.length === 0) {
        return null;
      }

      return (
        <NavSection
          key={section.title}
          pathname={pathname}
          items={items}
          title={section.title}
          disabledKeys={disabledKeys}
        />
      );
    })}
  </>
);
