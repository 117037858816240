import useSWR from "swr";

import { ApiError, Coach } from "types/api";

export const useCoachForUser = (userId?: string | null) => {
  const { data, error, mutate } = useSWR<Coach, ApiError>(
    userId ? `coach/user/${userId}` : null
  );

  return {
    coach: data,
    coachError: error,
    isCoachLoading: !data && !error,
    mutateCoach: mutate,
  };
};
