import useSWR from "swr";

import { ApiError, UserWithRoles } from "types/api";

export const useAdminUsers = () => {
  const { data, error, mutate } = useSWR<UserWithRoles[], ApiError>(
    "admin/user"
  );

  return {
    adminUsers: data,
    adminUsersError: error,
    isAdminUsersLoading: !data && !error,
    mutateAdminUsers: mutate,
  };
};
