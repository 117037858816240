import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, AssessmentControlTasks } from "types/api";

export const useAssessmentsWithUserControls = () => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<AssessmentControlTasks[], ApiError>(
    organizationId
      ? `organization/${organizationId}/assessment/user/controls`
      : null
  );

  return {
    assessmentsWithControls: data,
    assessmentsWithControlsError: error,
    isAssessmentsWithControlsLoading: !data && !error,
    mutateAssessmentsWithControls: mutate,
  };
};
