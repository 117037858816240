import useSWR from "swr";

import useAuth from "hooks/useAuth";

import { ApiError, IncidentStakeholderWithRole } from "types/api";

export const useIncidentStakeholder = (stakeholderId?: string | null) => {
  const { organizationId } = useAuth();
  const { data, error, mutate } = useSWR<IncidentStakeholderWithRole, ApiError>(
    typeof stakeholderId === "string" && organizationId
      ? `incident-management/${organizationId}/stakeholder/${stakeholderId}`
      : null
  );

  return {
    incidentStakeholder: data,
    incidentStakeholderError: error,
    isincidentStakeholderLoading: !data && !error,
    mutateincidentStakeholder: mutate,
  };
};
