import {
  School,
  Policy,
  Warning,
  Assessment,
  Dashboard,
  AccountCircle,
} from "@mui/icons-material";

import { NavSectionProps } from "components/NavSection";

import { ROUTES } from "constants/routes";
import Chat from "icons/ChatAlt";
import Resources from "icons/DocumentDuplicate";
import DocumentSearch from "icons/DocumentSearch";
import Key from "icons/Key";
import Lock from "icons/Lock";
import SpeakerPhone from "icons/SpeakerPhone";

export const sidebarRoutes: Pick<NavSectionProps, "items" | "title">[] = [
  {
    title: "",
    items: [
      {
        title: "Admin Dashboard",
        icon: <Key fontSize="small" />,
        key: "admin_dashboard",
        path: ROUTES.ADMIN_DASHBOARD,
      },
      {
        title: "Coach Dashboard",
        icon: <Key fontSize="small" />,
        key: "coach_dashboard",
        path: ROUTES.COACH_DASHBOARD,
      },
      {
        title: "Dashboard",
        icon: <Dashboard fontSize="small" />,
        key: "dashboard",
        path: ROUTES.DASHBOARD,
        showViewingAs: true,
      },
      {
        title: "Risk Registry",
        icon: <Warning fontSize="small" />,
        key: "risk_registry",
        path: ROUTES.RISK_REGISTRY,
        showViewingAs: true,
      },
      {
        title: "Assessments",
        icon: <Assessment fontSize="small" />,
        key: "assessments",
        path: ROUTES.ASSESSMENTS,
        showViewingAs: true,
      },
      {
        title: "Training",
        icon: <School fontSize="small" />,
        key: "training",
        path: ROUTES.TRAINING,
        showViewingAs: true,
      },
      {
        title: "Policy Manager",
        icon: <Policy fontSize="small" />,
        key: "policy_manager",
        path: ROUTES.POLICIES,
        showViewingAs: true,
      },
      {
        title: "Document Library",
        icon: <DocumentSearch fontSize="small" />,
        key: "document_library",
        path: ROUTES.DOCUMENTS,
        showViewingAs: true,
      },
      {
        title: "Coach",
        icon: <SpeakerPhone fontSize="small" />,
        key: "coach",
        path: ROUTES.COACH,
        showViewingAs: true,
      },
      {
        title: "Chat",
        icon: <Chat fontSize="small" />,
        key: "chat",
        path: ROUTES.CHAT,
      },
      {
        title: "Clients",
        icon: <AccountCircle fontSize="small" />,
        key: "clients",
        path: ROUTES.CLIENTS,
      },
      {
        title: "Organizations",
        icon: <AccountCircle fontSize="small" />,
        key: "organizations",
        path: ROUTES.ORGANIZATIONS,
      },
      {
        title: "Resources",
        icon: <Resources fontSize="small" />,
        key: "resources",
        path: ROUTES.RESOURCES,
      },
      {
        title: "Incident Management",
        icon: <Policy fontSize="small" />,
        key: "incident_management",
        path: ROUTES.INCIDENT_MANAGEMENT,
        showViewingAs: process.env.NEXT_PUBLIC_INCIDENT_TESTING_MODE === "true",
      },
      {
        title: "Privacy Community",
        icon: <Lock fontSize="small" />,
        key: "privacy_community",
        path: ROUTES.COMMUNITY,
      },
    ],
  },
  {
    title: "Admin",
    items: [
      {
        title: "Policy Templates",
        icon: <Key fontSize="small" />,
        key: "policy_templates",
        path: ROUTES.POLICY_TEMPLATES,
      },
      {
        title: "Assessment Templates",
        icon: <Key fontSize="small" />,
        key: "assessment_templates",
        path: ROUTES.ASSESSMENT_TEMPLATES,
      },
      {
        title: "PHI Settings",
        icon: <Key fontSize="small" />,
        key: "phi_settings",
        path: ROUTES.PHI_SETTINGS,
      },
    ],
  },
];

export const enterpriseSidebarRoutes: (
  organization_name: string
) => Pick<NavSectionProps, "items" | "title">[] = (
  organization_name: string
) => [
  {
    title: "Enterprise",
    items: [
      {
        title: "Admin Dashboard",
        icon: <Key fontSize="small" />,
        key: "admin_dashboard",
        path: ROUTES.ADMIN_DASHBOARD,
      },
      {
        title: "Coach Dashboard",
        icon: <Key fontSize="small" />,
        key: "coach_dashboard",
        path: ROUTES.COACH_DASHBOARD,
      },
      {
        title: "Dashboard",
        icon: <Dashboard fontSize="small" />,
        key: "dashboard",
        path: ROUTES.DASHBOARD,
        showViewingAs: true,
      },
      {
        title: "Risk Registry",
        icon: <Warning fontSize="small" />,
        key: "risk_registry",
        path: ROUTES.RISK_REGISTRY,
        showViewingAs: true,
      },
      {
        title: "Assessments",
        icon: <Assessment fontSize="small" />,
        key: "assessments",
        path: ROUTES.ASSESSMENTS,
        showViewingAs: true,
      },
      {
        title: "Chat",
        icon: <Chat fontSize="small" />,
        key: "chat",
        path: ROUTES.CHAT,
      },
      {
        title: "Clients",
        icon: <AccountCircle fontSize="small" />,
        key: "clients",
        path: ROUTES.CLIENTS,
      },
      {
        title: "Organizations",
        icon: <AccountCircle fontSize="small" />,
        key: "organizations",
        path: ROUTES.ORGANIZATIONS,
      },
      {
        title: "Resources",
        icon: <Resources fontSize="small" />,
        key: "resources",
        path: ROUTES.RESOURCES,
      },
      {
        title: "Incident Management",
        icon: <Policy fontSize="small" />,
        key: "incident_management",
        path: ROUTES.INCIDENT_MANAGEMENT,
      },
      {
        title: "Privacy Community",
        icon: <Lock fontSize="small" />,
        key: "privacy_community",
        path: ROUTES.COMMUNITY,
      },
    ],
  },
  {
    title: organization_name,
    items: [
      {
        title: "Training",
        icon: <School fontSize="small" />,
        key: "training",
        path: ROUTES.TRAINING,
        showViewingAs: true,
      },
      {
        title: "Policy Manager",
        icon: <Policy fontSize="small" />,
        key: "policy_manager",
        path: ROUTES.POLICIES,
        showViewingAs: true,
      },
      {
        title: "Document Library",
        icon: <DocumentSearch fontSize="small" />,
        key: "document_library",
        path: ROUTES.DOCUMENTS,
        showViewingAs: true,
      },
      {
        title: "Coach",
        icon: <SpeakerPhone fontSize="small" />,
        key: "coach",
        path: ROUTES.COACH,
        showViewingAs: true,
      },
    ],
  },
  {
    title: "Admin",
    items: [
      {
        title: "Assessment Templates",
        icon: <Key fontSize="small" />,
        key: "assessment_templates",
        path: ROUTES.ASSESSMENT_TEMPLATES,
      },
      {
        title: "PHI Settings",
        icon: <Key fontSize="small" />,
        key: "phi_settings",
        path: ROUTES.PHI_SETTINGS,
      },
    ],
  },
];
export const sidebarRouteOrganized = {
  titleTop: "Pinned Settings",
  title: "Organization Settings",
  icon: <Key fontSize="small" />,
  key: "org_settings",
  path: ROUTES.PROFILE_ORGANIZATION,
};

export const auditorSidebarRoutes: Pick<NavSectionProps, "items" | "title">[] =
  [
    {
      title: "Auditor",
      items: [
        {
          title: "Clients",
          icon: <AccountCircle fontSize="small" />,
          key: "clients",
          path: ROUTES.CLIENTS,
        },
      ],
    },
  ];

export const auditorViewAsSidebarRoutes: Pick<
  NavSectionProps,
  "items" | "title"
>[] = [
  {
    title: "Auditor",
    items: [
      {
        title: "Risk Registry",
        icon: <Warning fontSize="small" />,
        key: "risk_registry",
        path: ROUTES.RISK_REGISTRY,
        showViewingAs: true,
      },
      {
        title: "Assessments",
        icon: <Assessment fontSize="small" />,
        key: "assessments",
        path: ROUTES.ASSESSMENTS,
        showViewingAs: true,
      },
      {
        title: "Policy Manager",
        icon: <Policy fontSize="small" />,
        key: "policy_manager",
        path: ROUTES.POLICIES,
        showViewingAs: true,
      },
      {
        title: "Document Library",
        icon: <DocumentSearch fontSize="small" />,
        key: "document_library",
        path: ROUTES.DOCUMENTS,
        showViewingAs: true,
      },
      {
        title: "Clients",
        icon: <AccountCircle fontSize="small" />,
        key: "clients",
        path: ROUTES.CLIENTS,
      },
    ],
  },
];
